import React, { FunctionComponent, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { Account } from "src/models/app/Account";
import { AccountTag } from "src/models/app/AccountTag";
import { Tags } from "src/models/app/Tag";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { KYCItem } from "./KYCItem";
import DocumentsTable from "./DocumentsTable";
import { AccountDocumentStateCodes } from "../../../models/app/AccountDocumentState";
import FileUpload from "../../../components/fileUpload/FileUpload";
import SpinnerComp from "src/components/spinner/SpinnerComp";

export const MAX_FILE_SIZE = 5 * (1024 * 1024);
interface IKYCProps {
  account: Account;
  refreshAccount: () => void;
}

const kycCodeMapping: { [key: string]: { verified: Tags; required: Tags } } = {
  SOF: {
    verified: Tags.SOF_VERIFIED,
    required: Tags.SOF_REQUIRED,
  },
  POI: {
    verified: Tags.POI_VERIFIED,
    required: Tags.POI_REQUIRED,
  },
  POA: {
    verified: Tags.POA_VERIFIED,
    required: Tags.POA_REQUIRED,
  },
  POF: {
    verified: Tags.POF_VERIFIED,
    required: Tags.POF_REQUIRED,
  },
  VERIFIED: {
    verified: Tags.VERIFIED,
    required: Tags.UNVERIFIED,
  },
};

export const KYCTags: FunctionComponent<IKYCProps> = (props: IKYCProps) => {
  const accountContainsTag = (tag: Tags): boolean => {
    const res = props.account.accountTags.find(
      (accountTag) => accountTag.tagId === tag && accountTag.deletedAt === null,
    );
    return res !== undefined;
  };

  const getKYCItems = () => {
    return Object.keys(kycCodeMapping).map((kycKey, key) => {
      return (
        <KYCItem
          key={key}
          name={`${kycKey}`}
          onChange={async (event) => {
            try {
              setLoading(true);
              const tagToRemove: number = event
                ? kycCodeMapping[kycKey].required
                : kycCodeMapping[kycKey].verified;

              const tagToAdd: number = !event
                ? kycCodeMapping[kycKey].required
                : kycCodeMapping[kycKey].verified;

              const queriesConfig: Array<[string, number]> = [
                [urls.ACCOUNT_TAGS_REMOVE, tagToRemove],
                [urls.ACCOUNT_TAGS_ADD, tagToAdd],
              ];

              await Promise.all(
                queriesConfig.map(([url, tagId]) => {
                  return makeRequest<AccountTag[], any>("POST", url, {
                    accountId: props.account.id,
                    tagId,
                  });
                }),
              );
              props.refreshAccount();
              toastr.success("Tags updated!");
            } catch (e) {
              toastr.error((e as any).message);
            } finally {
              setLoading(false);
            }
          }}
          value={accountContainsTag(kycCodeMapping[kycKey].verified)}
        />
      );
    });
  };

  const [loading, setLoading] = useState(false);

  const deletedDocuments = props.account.accountDocuments
    ? props.account.accountDocuments.filter((doc) => doc.deletedAt != null)
    : [];

  const nonDeletedDocuments = props.account.accountDocuments
    ? props.account.accountDocuments.filter((doc) => doc.deletedAt === null)
    : [];

  const unverifiedDocuments = nonDeletedDocuments.filter(
    (doc) => doc.state.code === AccountDocumentStateCodes.UNVERIFIED,
  );

  const verifiedDocuments = nonDeletedDocuments.filter(
    (doc) => doc.state.code === AccountDocumentStateCodes.VERIFIED,
  );

  const rejectedDocuments = nonDeletedDocuments.filter(
    (doc) => doc.state.code === AccountDocumentStateCodes.REJECTED,
  );

  const expiredDocuments = nonDeletedDocuments.filter(
    (doc) => doc.state.code === AccountDocumentStateCodes.EXPIRED,
  );

  const manualTransactionDocuments = nonDeletedDocuments.filter(
    (doc) => doc.state.code === AccountDocumentStateCodes.MANUAL_TRANSACTION,
  );

  return (
    <>
      <Card>
        <Card.Header>KYC Status</Card.Header>
        <Card.Body className="text-center pt-2">
          {loading ? (
            <SpinnerComp animation="border" />
          ) : (
            <Row>{getKYCItems()} </Row>
          )}
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Upload Document</Card.Header>
        <FileUpload
          account={props.account}
          refreshAccount={props.refreshAccount}
        />
      </Card>
      <br />

      <Card>
        <Card.Header>Unverified KYC Documents </Card.Header>
        <Card.Body>
          {unverifiedDocuments && unverifiedDocuments.length > 0 ? (
            <DocumentsTable
              documents={unverifiedDocuments}
              requireActionButtons={true}
              refreshAccount={props.refreshAccount}
              expiryDate={false}
            />
          ) : (
            <p className="text-center"> No results</p>
          )}
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Verified KYC Documents </Card.Header>
        <Card.Body>
          {verifiedDocuments && verifiedDocuments.length > 0 ? (
            <DocumentsTable
              documents={verifiedDocuments}
              requireActionButtons={false}
              refreshAccount={props.refreshAccount}
              expiryDate={true}
            />
          ) : (
            <p className="text-center"> No results</p>
          )}
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Rejected KYC Documents </Card.Header>
        <Card.Body>
          {rejectedDocuments && rejectedDocuments.length > 0 ? (
            <DocumentsTable
              documents={rejectedDocuments}
              requireActionButtons={false}
              refreshAccount={props.refreshAccount}
              expiryDate={false}
            />
          ) : (
            <p className="text-center"> No results</p>
          )}
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Expired KYC Documents </Card.Header>
        <Card.Body>
          {expiredDocuments && expiredDocuments.length > 0 ? (
            <DocumentsTable
              documents={expiredDocuments}
              requireActionButtons={false}
              refreshAccount={props.refreshAccount}
              expiryDate={true}
            />
          ) : (
            <p className="text-center"> No results</p>
          )}
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Manual Transaction Documents</Card.Header>
        <Card.Body>
          {manualTransactionDocuments &&
          manualTransactionDocuments.length > 0 ? (
            <DocumentsTable
              documents={manualTransactionDocuments}
              requireActionButtons={false}
              refreshAccount={props.refreshAccount}
              expiryDate={true}
            />
          ) : (
            <p className="text-center">No results</p>
          )}
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Deleted KYC Documents </Card.Header>
        <Card.Body>
          {deletedDocuments && deletedDocuments.length > 0 ? (
            <DocumentsTable
              documents={deletedDocuments}
              requireActionButtons={false}
              refreshAccount={props.refreshAccount}
              expiryDate={false}
            />
          ) : (
            <p className="text-center"> No results</p>
          )}
        </Card.Body>
      </Card>
      <br />
    </>
  );
};
