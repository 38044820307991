import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import BackofficeAccountsList from "./BackofficeAccountsList/BackofficeAccountsList";

export class BackofficeAccountsPage extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/backoffice-accounts">
            <Jumbotron className="m-4">
              <h3>Backoffice Accounts List</h3>
              <BackofficeAccountsList />
            </Jumbotron>
          </Route>
        </Switch>
      </div>
    );
  }
}
