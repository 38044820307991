/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Table,
  Jumbotron,
  Card,
  Col,
  Form,
  Row,
  Button,
} from "react-bootstrap";
import { safeFormatDate } from "src/utilities/date";
import { makeRequest } from "src/utilities/axio.helper";
import { RequestGetVIPInfoDto } from "src/models/dto/vip.dto";
import urls from "src/utilities/urls";

import { AccountTier } from "src/models/app/AccountTier";
import { AccountVipEnrolled } from "src/models/app/AccountVipEnrolled";

interface VIPAccountID {
  accountId: number;
}

const AccountVipInfo: FunctionComponent<VIPAccountID> = ({ accountId }) => {
  const [accountVIPInfo, setaccountVIPInfo] = useState<AccountTier[]>();
  const [accountVIPEnrollmentInfo, setaccountVIPEnrollmentInfo] =
    useState<AccountVipEnrolled[]>();
  const [checked, setChecked] = useState(false);
  const [getDataStatus, setgetDataStatus] = useState(false);

  const fetchVipInfo = async (): Promise<void> => {
    const response = await makeRequest<AccountTier[], RequestGetVIPInfoDto>(
      "post",
      urls.ACCOUNT_VIP_INFO,
      {
        accountId: accountId,
      },
    );

    setaccountVIPInfo(response);
  };

  const fetchVipEnrollmentInfo = async (): Promise<void> => {
    const response = await makeRequest<
      AccountVipEnrolled[],
      RequestGetVIPInfoDto
    >("post", urls.ACCOUNT_VIP_ENROLLMENT_INFO, {
      accountId: accountId,
    });
    setaccountVIPEnrollmentInfo(response);
    if (!response) {
      setChecked(false);
      return;
    }

    const validEnrollmentData = response.find((r) => r.validTo === null);
    if (validEnrollmentData) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  const upsertVipEnrollmentInfo = async (): Promise<void> => {
    await makeRequest<undefined, RequestGetVIPInfoDto>(
      "post",
      urls.ACCOUNT_VIP_ENROLLMENT_UPSERT,
      {
        accountId: accountId,
      },
    );
  };

  useEffect(() => {
    if (getDataStatus) {
      fetchVipInfo();
      fetchVipEnrollmentInfo();
    }
  }, [getDataStatus]);

  function ReactSwitch(checked: boolean, setChecked: any) {
    const save = async (): Promise<void> => {
      setChecked(!checked);
      upsertVipEnrollmentInfo();
    };

    return (
      <>
        <Col>
          <Form.Check
            id="0"
            label=""
            type="switch"
            checked={checked}
            onChange={() => save()}
          />
        </Col>
      </>
    );
  }

  function vipView() {
    return (
      <>
        <Card className="m-4">
          <Card.Header>
            <Row className="ml-1 d-flex align-items-center">
              <h3>VIP Enrollment</h3>
              {ReactSwitch(checked, setChecked)}
            </Row>
          </Card.Header>

          <Card.Body>
            <h5 className="ml-1">VIP Tier Info</h5>

            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Account Tier ID</th>
                  <th>Tier</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {accountVIPInfo &&
                  accountVIPInfo.map((AccountTierChange: AccountTier) => {
                    return (
                      <tr key={AccountTierChange.id}>
                        <td>{AccountTierChange.id}</td>
                        <td>{AccountTierChange.tier}</td>
                        <td>{AccountTierChange.createdAt}</td>
                        <td>{safeFormatDate(AccountTierChange.updatedAt)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>

          <Card.Body>
            <h5 className="ml-1">VIP Enrollment Info</h5>

            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Account Enrollment ID</th>
                  <th>Valid To</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {accountVIPEnrollmentInfo &&
                  accountVIPEnrollmentInfo.map(
                    (accountVipEnrolled: AccountVipEnrolled) => {
                      return (
                        <tr key={accountVipEnrolled.id}>
                          <td>{accountVipEnrolled.id}</td>
                          <td>{accountVipEnrolled.validTo ?? "VALID"}</td>
                          <td>{accountVipEnrolled.createdAt}</td>
                          <td>
                            {safeFormatDate(accountVipEnrolled.updatedAt)}
                          </td>
                        </tr>
                      );
                    },
                  )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setgetDataStatus(true);
  };

  return (
    <Jumbotron className="m-4">
      {getDataStatus ? vipView() : getDataView()}
    </Jumbotron>
  );
};

export default AccountVipInfo;
