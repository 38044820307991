import React, { FunctionComponent, useState } from "react";
import DayJS from "dayjs";
import { AccountDocument } from "../../../models/app/AccountDocument";
import config from "src/config";
import Icon from "../../../components/icon/Icon";
import { Button } from "react-bootstrap";
import { DocumentApprovalModal } from "./DocumentApprovalModal";
import Swal, { SweetAlertResult } from "sweetalert2";
import { makeRequest } from "../../../utilities/axio.helper";
import urls from "../../../utilities/urls";
import {
  RequestDeleteAccountDocumentsDto,
  RequestRejectAccountDocumentsDto,
} from "../../../models/dto/document.dto";

interface ITableRowComponent {
  document: AccountDocument;
  index: number;
  requireActionButtons: boolean;
  refreshAccount: () => void;
  expiryDate: boolean;
}

const deleteDocumentModal = async (
  props: ITableRowComponent,
): Promise<void> => {
  const title = "Are you sure you want to delete this document?";

  const result: SweetAlertResult = await Swal.fire({
    title: title,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes!",
  });

  if (result.isConfirmed) {
    try {
      await makeRequest<AccountDocument, RequestDeleteAccountDocumentsDto>(
        "post",
        urls.DOCUMENTS_DELETE,
        {
          documentId: props.document.id,
        },
      );

      toastr.success("Document Deleted!");

      // Refresh list
      props.refreshAccount();
    } catch (err) {
      toastr.error((err as any).message);
    }
  }
};
const rejectModal = async (props: ITableRowComponent): Promise<void> => {
  const title = "Are you sure you want to reject this document?";

  const result: SweetAlertResult = await Swal.fire({
    title: title,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes!",
  });

  if (result.isConfirmed) {
    try {
      await makeRequest<AccountDocument, RequestRejectAccountDocumentsDto>(
        "post",
        urls.DOCUMENTS_REJECT,
        {
          documentId: props.document.id,
        },
      );

      toastr.success("Document Rejected!");

      // Refresh list
      props.refreshAccount();
    } catch (err) {
      toastr.error((err as any).message);
    }
  }
};

const TableRow: FunctionComponent<ITableRowComponent> = (
  props: ITableRowComponent,
) => {
  const [showApproveDocumentModal, setApproveDocumentModal] =
    useState<boolean>(false);

  const showApproveModal = (): void => setApproveDocumentModal(true);
  const hideApproveModal = (): void => setApproveDocumentModal(false);

  const getApprovalModal = (): React.ReactChild => {
    if (showApproveDocumentModal && props.document) {
      return (
        <DocumentApprovalModal
          document={props.document}
          removeModal={hideApproveModal}
          onUpdated={() => {
            // Refresh the account
            props.refreshAccount();
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <tr key={props.index} className={"text-center"}>
        <td>{props.document.id}</td>
        <td>
          {!props.document.deletedAt ? (
            <a
              href={`${config.GCP_STORAGE_BUCKET_URL}${props.document.bucket}/${props.document.path}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.document.name} <Icon name="external-link" />
            </a>
          ) : (
            <>{props.document.name}</>
          )}
        </td>
        <td>{props.document.type ? props.document.type.name : ""}</td>
        <td>{props.document.state ? props.document.state.name : ""}</td>
        <td>{DayJS(props.document.createdAt).format("DD/MM/YYYY hh:mm:ss")}</td>
        <td>{DayJS(props.document.updatedAt).format("DD/MM/YYYY hh:mm:ss")}</td>
        <td>
          {props.document.expiryDate &&
            DayJS(props.document.expiryDate).format("DD/MM/YYYY")}
        </td>
        <td>
          {props.document.deletedAt &&
            DayJS(props.document.deletedAt).format("DD/MM/YYYY hh:mm:ss")}
        </td>
        {props.requireActionButtons && (
          <td>
            {!props.document.deletedAt && (
              <>
                <Button
                  className="mr-1"
                  variant="primary"
                  onClick={showApproveModal}
                >
                  {"Approve"}
                </Button>
                <Button
                  className="mr-1"
                  variant="primary"
                  onClick={() => rejectModal(props)}
                >
                  Reject
                </Button>

                <Button
                  className="mr-1"
                  variant="danger"
                  style={{ backgroundColor: "red" }}
                  onClick={() => deleteDocumentModal(props)}
                >
                  Delete
                </Button>
              </>
            )}
          </td>
        )}
        {props.document.stateId === 3 && (
          <td>
            {!props.document.deletedAt && (
              <>
                <Button
                  className="mr-1"
                  variant="primary"
                  onClick={showApproveModal}
                >
                  Edit
                </Button>

                <Button
                  className="mr-1"
                  variant="danger"
                  style={{ backgroundColor: "red" }}
                  onClick={() => deleteDocumentModal(props)}
                >
                  Delete
                </Button>
              </>
            )}
          </td>
        )}
      </tr>
      {getApprovalModal()}
    </>
  );
};

export default TableRow;
