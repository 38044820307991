import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import TransactionList from "./TransactionList";

export class TransactionsPage extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/transactions">
            <Jumbotron className="m-4">
              <h3>Transaction List</h3>
              <TransactionList />
            </Jumbotron>
          </Route>
        </Switch>
      </div>
    );
  }
}
