import axios, { Method, AxiosBasicCredentials } from "axios";
import config from "../config";
import { YoErrorResponse } from "src/models/YoErrorResponse";
import { AxiosError } from "axios";
import { getAccount } from "./storage";

export const makeRequest = async <T = any, F = any>(
  method: Method,
  url: string,
  data?: F,
  auth?: AxiosBasicCredentials,
  baseURL?: string,
): Promise<T> => {
  try {
    const account = getAccount();
    const response = await axios.request<T>({
      method,
      data,
      baseURL: baseURL ? baseURL : config.API_URL,
      headers: {
        "API-TOKEN": account?.token,
      },
      auth,
      responseType: "json",
      url,
    });

    return response.data as T;
  } catch (error) {
    const axiosError = error as AxiosError;

    const customError: YoErrorResponse = new YoErrorResponse();
    customError.message = axiosError.message;
    customError.stack = axiosError.stack;

    const axiosResponse = axiosError.response;
    if (axiosResponse && axiosResponse.data) {
      customError.message = axiosResponse.data.message || axiosError.message;
      customError.code = axiosResponse.data.code;
      customError.stack = axiosResponse.data.stack;
    }

    throw customError;
  }
};
