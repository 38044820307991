import React, { FunctionComponent } from "react";
import logo from "../../assets/logo.png";
interface LogoProps {
  size?: string;
}

const Logo: FunctionComponent<LogoProps> = ({ size }) => {
  return (
    <div>
      <img style={{ width: size }} alt="Freja Logo" src={logo} />
    </div>
  );
};

export default Logo;
