/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { AccountDocument } from "../../../models/app/AccountDocument";
import { AccountDocumentTypeCodes } from "../../../models/app/AccountDocumentType";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  RequestVerifyAccountDocumentsDto,
  RequestUpdateExpiryDateDto,
} from "../../../models/dto/document.dto";
import urls from "../../../utilities/urls";
import DayJS from "dayjs";

export interface IDocumentApprovalModal {
  document: AccountDocument;
  removeModal: () => void;
  onUpdated: () => void;
}

export const DocumentApprovalModal: FunctionComponent<
  IDocumentApprovalModal
> = (props: IDocumentApprovalModal) => {
  const [validDocumentType, setValidDocumentType] = useState<boolean>(true);
  const [isSOFChecked, setSOFChecked] = useState<boolean>(false);
  const [isPOFChecked, setPOFChecked] = useState<boolean>(false);
  const [isPOIChecked, setPOIChecked] = useState<boolean>(false);
  const [isPOAChecked, setPOAChecked] = useState<boolean>(false);
  const [expiryDate, setExpireDate] = useState<Date>(
    DayJS("2025-02-28", "YYYY-MM-DD").toDate(),
  );

  useEffect(() => {
    if (props.document.expiryDate) {
      setExpireDate(DayJS(props.document.expiryDate, "YYYY-MM-DD").toDate());
    }
  }, []);

  useEffect(() => {
    if (!props.document.expiryDate) {
      if (isPOAChecked) {
        setExpireDate(DayJS().add(1, "year").toDate());
      } else {
        setExpireDate(DayJS("2025-02-28", "YYYY-MM-DD").toDate());
      }
    }
  }, [isPOAChecked, isPOIChecked, isPOFChecked, isSOFChecked]);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      const documentTypes: string[] = [];
      if (!expiryDate || expiryDate.toString() === "Invalid Date") {
        toastr.error("Please select an expiry date");
        return;
      }

      if (props.document.stateId === 3) {
        await makeRequest<AccountDocument, RequestUpdateExpiryDateDto>(
          "post",
          urls.DOCUMENTS_UPDATE_EXPIRY,
          {
            documentId: props.document.id,
            expiryDate: expiryDate,
          },
        );

        props.onUpdated();

        toastr.success("Expiry Date was updated!");

        onHide();
      } else {
        if (isSOFChecked) {
          documentTypes.push(AccountDocumentTypeCodes.SOURCE_OF_FUNDS);
        }
        if (isPOFChecked) {
          documentTypes.push(AccountDocumentTypeCodes.PROOF_OF_FUNDS);
        }
        if (isPOIChecked) {
          documentTypes.push(AccountDocumentTypeCodes.PROOF_OF_IDENTITY);
        }
        if (isPOAChecked) {
          documentTypes.push(AccountDocumentTypeCodes.PROOF_OF_ADDRESS);
        }

        if (!documentTypes || documentTypes.length === 0) {
          setValidDocumentType(false);
          toastr.error("Please select a document type");
          return;
        }

        await makeRequest<AccountDocument, RequestVerifyAccountDocumentsDto>(
          "post",
          urls.DOCUMENTS_VERIFY,
          {
            documentId: props.document.id,
            documentTypeCodes: documentTypes,
            expiryDate: expiryDate,
          },
        );

        props.onUpdated();

        toastr.success("Document was approved!");

        onHide();
      }
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const getDocumentTypes = (): React.ReactChild => {
    return (
      <>
        <>
          <Form.Check
            label="Source of Funds"
            name={AccountDocumentTypeCodes.SOURCE_OF_FUNDS}
            type="checkbox"
            key={AccountDocumentTypeCodes.SOURCE_OF_FUNDS}
            id={AccountDocumentTypeCodes.SOURCE_OF_FUNDS}
            checked={isSOFChecked}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              if (evt.target) {
                if (evt.target.checked) {
                  setSOFChecked(true);
                } else {
                  setSOFChecked(false);
                }
              }
            }}
          />
          <Form.Check
            label="Proof of Identity"
            name={AccountDocumentTypeCodes.PROOF_OF_IDENTITY}
            type="checkbox"
            key={AccountDocumentTypeCodes.PROOF_OF_IDENTITY}
            id={AccountDocumentTypeCodes.PROOF_OF_IDENTITY}
            checked={isPOIChecked}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              if (evt.target) {
                if (evt.target.checked) {
                  setPOIChecked(true);
                } else {
                  setPOIChecked(false);
                }
              }
            }}
          />
          <Form.Check
            label="Proof of Address"
            name={AccountDocumentTypeCodes.PROOF_OF_ADDRESS}
            type="checkbox"
            key={AccountDocumentTypeCodes.PROOF_OF_ADDRESS}
            id={AccountDocumentTypeCodes.PROOF_OF_ADDRESS}
            checked={isPOAChecked}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              if (evt.target) {
                if (evt.target.checked) {
                  setPOAChecked(true);
                } else {
                  setPOAChecked(false);
                }
              }
            }}
          />
          <Form.Check
            label="Proof of Funds"
            name={AccountDocumentTypeCodes.PROOF_OF_FUNDS}
            type="checkbox"
            key={AccountDocumentTypeCodes.PROOF_OF_FUNDS}
            id={AccountDocumentTypeCodes.PROOF_OF_FUNDS}
            checked={isPOFChecked}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              if (evt.target) {
                if (evt.target.checked) {
                  setPOFChecked(true);
                } else {
                  setPOFChecked(false);
                }
              }
            }}
          />
        </>
      </>
    );
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.document.stateId === 3
              ? "Update Exipiry Date"
              : "Approve Document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {props.document.stateId !== 3 && (
              <Form.Group controlId="approval">
                <Form.Label>Please select the document type</Form.Label>
                {getDocumentTypes()}
              </Form.Group>
            )}
            <Form.Group controlId="approval">
              <Form.Label>Please select the expire date</Form.Label>
              <Form.Row>
                <Form.Control
                  type="date"
                  placeholder="ex. 24/05/1997"
                  value={DayJS(expiryDate).format("YYYY-MM-DD")}
                  onChange={(evt: any): void => {
                    if (evt.target) {
                      setExpireDate(
                        DayJS(evt.target.value, "YYYY-MM-DD").toDate(),
                      );
                    }
                  }}
                />
              </Form.Row>
            </Form.Group>
          </Form>
          {!validDocumentType && (
            <Alert variant={"danger"}>Document type was not selected</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSave();
            }}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};
