/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState } from "react";
import { Table, Card, Row, Button, Alert, Spinner } from "react-bootstrap";
import { Account } from "src/models/app/Account";
import { AccountTag } from "src/models/app/AccountTag";
import { AccountTagsItem } from "./AccountTagsItem";
import urls from "src/utilities/urls";
import { makeRequest } from "src/utilities/axio.helper";
import {
  RequestSpelpausDto,
  ResponseSpelpausDto,
} from "src/models/dto/spelpaus.dto";

interface IAccountCommentsProps {
  account: Account;
  refresh: boolean;
}

export const AccountTags: FunctionComponent<IAccountCommentsProps> = (
  props: IAccountCommentsProps,
) => {
  const [spelpausChecked, setSpelpausChecked] = useState<boolean>(false);
  const [spelpausLoginCheck, setSpelpausLoginCheck] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSpelpausCheck = async () => {
    if (loading) {
      toastr.warning("Please wait..");
      return;
    }

    if (!props.account.documentNumber) {
      toastr.warning("Invalid document number, please check.");
      return;
    }

    try {
      setLoading(true);

      const result = await makeRequest<ResponseSpelpausDto, RequestSpelpausDto>(
        "get",
        `${urls.ACCOUNTS_BLOCKING_INFO}?subjectId=${props.account.documentNumber}`,
      );

      setSpelpausLoginCheck(result.isBlocked);
      setSpelpausChecked(true);

      toastr.success("Spelpaus status retrieved!");
    } catch (e) {
      toastr.error((e as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>Tags</Card.Header>
      <Card.Body>
        <Row>
          <Button
            className="mb-2 ml-4 w-40 h-30"
            disabled={loading}
            onClick={handleSpelpausCheck}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Check Spelpaus Blocked"
            )}
          </Button>
          {spelpausChecked ? (
            // <input className="ml-4 p-1 height text-center" disabled value={spelpausCheck ? "Blocked" : "Not Blocked"} />
            <Alert className="mb-2 ml-4 h-30 text-center" variant="secondary">
              <span style={{ fontWeight: 700 }}>Spelpaus:</span>{" "}
              {spelpausLoginCheck ? "Blocked" : "Not Blocked"}
            </Alert>
          ) : (
            <></>
          )}
        </Row>

        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Tag</th>
              <th>First record</th>
              <th>Modified</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {props.account.accountTags.map((accountTag: AccountTag) => {
              return (
                <AccountTagsItem
                  key={accountTag.tagId}
                  {...accountTag}
                ></AccountTagsItem>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
