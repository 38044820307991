import React, { FunctionComponent, useState } from "react";
import GameList from "../GameListPage/GameList";
import AddGameManuallyPage from "../AddGameManuallyPage/AddGameManuallyPage";
import { Game } from "../../../models/app/Game";
import { Route, Switch, useHistory } from "react-router-dom";
import GameEditPage from "../GameEditPage/GameEditPage";
import urls from "../../../utilities/urls";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  DeleteGameDto,
  RequestUpdateGamesDto,
  ResponseUpdateGamesDto,
} from "src/models/dto/game.dto";
import { Jumbotron, Button } from "react-bootstrap";
import config from "src/config";
import AddGameJackpotManuallyPage from "../AddGameJackpotManuallyPage/AddGameJackpotManuallyPage";
import SpinnerComp from "src/components/spinner/SpinnerComp";

const GamesPage: FunctionComponent = () => {
  const history = useHistory();
  const [updatingGames, setUpdatingGames] = useState<boolean>(false);

  const deleteGame = async (gameId: number): Promise<void> => {
    try {
      await makeRequest<Game, DeleteGameDto>("delete", urls.GAMES_LIST, {
        id: gameId,
      });
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const updateGames = async (): Promise<void> => {
    try {
      setUpdatingGames(true);
      const response = await makeRequest<
        ResponseUpdateGamesDto,
        RequestUpdateGamesDto
      >("post", `${config.BACKEND_API_URL}casino/games/update`, undefined, {
        username: config.API_AUTH.USERNAME,
        password: config.API_AUTH.PASSWORD,
      });

      setUpdatingGames(false);
      toastr.success(
        `${response.message} (New Games: ${response.newGameCount})`,
      );
    } catch (err) {
      setUpdatingGames(false);
      toastr.error((err as any).message);
    }
  };

  const addGameManually = async (): Promise<void> => {
    try {
      history.push("/games/add");
    } catch (err) {
      toastr.error((err as Error).message);
    }
  };

  const addJackpotGame = async (): Promise<void> => {
    try {
      history.push("/games/add-jackpot");
    } catch (err) {
      toastr.error((err as Error).message);
    }
  };

  return (
    <div>
      <Switch>
        <Route path="/games/add/">
          <AddGameManuallyPage />
        </Route>
        <Route path="/games/add-jackpot">
          <AddGameJackpotManuallyPage />
        </Route>
        <Route path="/games/edit/:gameId?">
          <GameEditPage />
        </Route>
        <Route path="/games">
          <Jumbotron className="m-4">
            <h2 className="ml-4 mb-4">Game List</h2>
            <div className="ml-4">
              <Button
                style={{ height: "3rem", width: "15%" }}
                onClick={() => updateGames()}
              >
                {updatingGames ? (
                  <SpinnerComp animation="border" />
                ) : (
                  "Update Games List"
                )}
              </Button>{" "}
              <Button
                style={{ height: "3rem", width: "15%" }}
                className="ml-4"
                onClick={() => addGameManually()}
              >
                Add Game Manually
              </Button>
              <Button
                style={{ height: "3rem", width: "15%" }}
                className="ml-4"
                onClick={() => addJackpotGame()}
              >
                Add Jackpot Game
              </Button>
            </div>

            <GameList deleteGame={deleteGame}></GameList>
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default GamesPage;
