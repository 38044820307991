/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Limit } from "src/models/app/Limit";
import { RequestUpsertLimitDto } from "src/models/dto/limit.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";

export interface IAccountLimitUpdateModal {
  limit: Limit;
  account: Account;
  removeModal: () => void;
  onUpdate: (limit: Limit) => void;
}

export const AccountLimitUpdateModal: FunctionComponent<
  IAccountLimitUpdateModal
> = (props: IAccountLimitUpdateModal) => {
  const [limit, setLimit] = useState<Limit>(new Limit());
  const [, setLimitTypes] = useState<string[]>([]);

  useEffect(() => {
    setLimitTypes(["DEPOSIT", "SESSION", "EXCLUSION"]);
    setLimit(props.limit);
  }, []);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      // Create Limit
      await makeRequest<Limit, RequestUpsertLimitDto>("post", urls.LIMITS, {
        limit: { ...limit, account: props.account },
      });

      toastr.success("Created Limit!");

      props.onUpdate(limit);
      onHide();
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="limitType">
              <Form.Label>Limit Type</Form.Label>
              <Form.Control readOnly as="select" custom value={limit.type}>
                <option key={limit.type} value={limit.type}>
                  {limit.type}{" "}
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="hours">
              <Form.Label>Limit Hours</Form.Label>
              <Form.Control
                type="number"
                value={limit.amount}
                onChange={(option: any): void => {
                  if (
                    option.target.value < 1 ||
                    option.target.value > limit.hours
                  ) {
                    toastr.error(
                      `INVALID INPUT: Range accepted 1 - ${limit.hours}`,
                    );
                    return;
                  }
                  setLimit({
                    ...limit,
                    amount: Number(option.target.value),
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};
