/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Account } from "./Account";

export enum AccountStatusType {
  SMS_MARKETING = "SMS_MARKETING",
  EMAIL_MARKETING = "EMAIL_MARKETING",
  BLOCKED_WITHDRAWALS = "BLOCKED_WITHDRAWALS",
  ACCOUNT = "ACCOUNT",
}
export enum AccountStatusChangeReason {
  SELF_EXCLUSION = "SELF_EXCLUSION",
  WRONG_PASSWORD = "WRONG_PASSWORD",
  SPELPAUS = "SPELPAUS",
  CLOSED = "CLOSED",
}

export class AccountStatusLog {
  id: number;

  status: number;

  type: AccountStatusType;

  reason: AccountStatusChangeReason;

  createdAt: Date;

  account: Account;
}
