/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */

export class ManuallyAddedGame {
  name: string;

  externalId: string;

  providerId: number;

  active: number = 0;
}
