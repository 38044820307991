/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import {
  Accordion,
  Button,
  Card,
  Table,
  Form,
  Pagination,
} from "react-bootstrap";

import {
  LoginHistory,
  ResponseGetLoginHistoryDto,
} from "src/models/app/LoginHistory";
import { useParams } from "react-router-dom";
import { safeFormatDate } from "src/utilities/date";

class FilterValues {
  id: number[] = [];
  successful: number;
  errorCode: string;
}

const LoginHistoryList: FunctionComponent<any> = () => {
  const [loginHistory, setLoginHistory] =
    useState<ResponseGetLoginHistoryDto>();
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationShowAmount] = useState<number>(10);
  const [filterValues, setFilterValues] = useState<FilterValues>(
    new FilterValues(),
  );
  const [errorCode, setErrorCode] = useState<string[]>([]);
  const [getDataStatus, setgetDataStatus] = useState(false);

  const { accountId } = useParams<{
    accountId?: string;
  }>();

  function uniqBySetWithArrayFrom<T>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  const getErrorCodes = async () => {
    const response = await makeRequest<ResponseGetLoginHistoryDto>(
      "post",
      urls.GET_ERROR_CODES,
      {
        where: {
          accountId: Number(accountId),
        },
      },
    );
    const newErrorCodes = response.loginHistory.map((login) => {
      return login.errorCode;
    });
    setErrorCode(uniqBySetWithArrayFrom(newErrorCodes));
  };

  const successfulOptions = [
    {
      key: "select",
      value: "Select",
    },
    {
      key: "1",
      value: "True",
    },
    {
      key: "0",
      value: "False",
    },
  ];

  const getPagination = (): React.ReactChild => {
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;

    const items = [];
    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    return (
      <Pagination className="m-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
            getPagination();
          }}
        />
      </Pagination>
    );
  };

  async function refreshGames(filterValues: FilterValues) {
    const where: LoginHistory = new LoginHistory();

    if (filterValues?.successful === 0 || filterValues?.successful === 1) {
      where.successful = filterValues.successful;
    } else {
      where.successful = -1;
    }

    if (filterValues?.errorCode?.length > 0) {
      where.errorCode = filterValues.errorCode;
    }
    const response = await makeRequest<ResponseGetLoginHistoryDto>(
      "post",
      urls.LOGIN_HISTORY,
      {
        where: {
          successful: where.successful,
          accountId: Number(accountId),
          errorCode: where.errorCode === "select" ? "" : where.errorCode,
        },
        offset: paginationShowAmount * (paginationIndex - 1),
        limit: paginationShowAmount,
      },
    );
    setLoginHistory(response);
    setPaginationTotalPages(
      Math.ceil(response.totalHistory / paginationShowAmount),
    );
  }

  useEffect(() => {
    if (getDataStatus) {
      getErrorCodes();
    }
  }, [getDataStatus]);

  useEffect(() => {
    if (getDataStatus) {
      setPaginationIndex(1);
    }
  }, [getDataStatus, filterValues]);

  useEffect(() => {
    if (getDataStatus) {
      refreshGames(filterValues);
    }
  }, [getDataStatus, paginationIndex, paginationShowAmount, filterValues]);

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setgetDataStatus(true);
  };

  const getHistoryView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Filter
            </Accordion.Toggle>
          </Card.Header>
          <Card.Body>
            <Form
              onSubmit={(e: React.FormEvent<HTMLElement>): void =>
                e.preventDefault()
              }
            >
              <Form.Group controlId="successful">
                <Form.Label>Successful</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    let successful = undefined;
                    if (evt.target.value) {
                      successful = evt.target.value;
                    }

                    setFilterValues({
                      ...filterValues,
                      successful: Number(successful),
                    });
                  }}
                >
                  {successfulOptions.map((option) => {
                    return (
                      <option key={option.key} value={option.key}>
                        {option.value}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="errorCode">
                <Form.Label>Error Code</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    let code = undefined;
                    if (evt.target.value) {
                      code = evt.target.value;
                    }
                    setFilterValues({
                      ...filterValues,
                      errorCode: code === undefined ? "" : code,
                    });
                  }}
                >
                  <option value="select">Select</option>
                  {errorCode.map((error) => {
                    return (
                      <option key={error} value={error}>
                        {error}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <Pagination className="m-4 flex-wrap">{getPagination()}</Pagination>
        <Card>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Account Id</th>
                  <th>Successful</th>
                  <th>Error Code</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {loginHistory?.loginHistory &&
                  loginHistory?.loginHistory.map(
                    (loginHistory: LoginHistory) => {
                      return (
                        <tr key={loginHistory.id}>
                          <td>{loginHistory.id}</td>
                          <td>{loginHistory.account_id}</td>
                          <td>
                            {loginHistory.successful === 1 ? "true" : "false"}
                          </td>
                          <td>
                            {loginHistory.errorCode === "null" ||
                            loginHistory.errorCode === null
                              ? "-"
                              : loginHistory.errorCode}
                          </td>
                          <td>{safeFormatDate(loginHistory.createdAt)}</td>
                        </tr>
                      );
                    },
                  )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  };

  return <>{getDataStatus ? getHistoryView() : getDataView()}</>;
};

export default LoginHistoryList;
