import React, { FunctionComponent } from "react";
import Table from "react-bootstrap/esm/Table";
import {
  ResponseGetCommentDto,
  ResponseGetComments,
} from "src/models/dto/comment.dto";

interface ICommentProps {
  comments: ResponseGetComments;
}

const CommentsTable: FunctionComponent<ICommentProps> = (
  props: ICommentProps,
) => {
  return props.comments?.comments?.length > 0 ? (
    <Table striped bordered hover className="bg-white">
      <thead>
        <tr>
          <th>#</th>
          <th>Comment Creator</th>
          <th>Account</th>
          <th>Type</th>
          <th>Action</th>
          <th>Comment</th>
          <th>Created At</th>
          <th>Updated At</th>
        </tr>
      </thead>
      <tbody>
        {props?.comments?.comments.map((comment: ResponseGetCommentDto) => (
          <tr key={comment.comment.id}>
            <td>{comment.comment.id}</td>
            <td>{`${
              comment.backOfficeAccount?.name
                ? comment.backOfficeAccount?.name
                : ""
            } ${
              comment.backOfficeAccount?.id
                ? "(" + comment.backOfficeAccount?.id + ")"
                : ""
            }`}</td>
            <td>{`${comment.comment.account.name} (${comment.comment.account.id})`}</td>
            <td>{comment.comment.type}</td>
            <td>{comment.comment.action}</td>
            <td>{comment.comment.comment}</td>
            <td>{comment.comment.createdAt}</td>
            <td>{comment.comment.updatedAt}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <></>
  );
};

export default CommentsTable;
