import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import ProvidersList from "./ProvidersList/ProvidersList";
import ProviderGamesList from "./ProviderGamesList/ProviderGamesList";

export class ProvidersPage extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/providers/gamelist/:providerId">
            <ProviderGamesList />
          </Route>
          <Route path="/providers">
            <Jumbotron className="m-4">
              <h3>Providers List</h3>
              <ProvidersList />
            </Jumbotron>
          </Route>
        </Switch>
      </div>
    );
  }
}
