/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Language } from "./Languages";
import { Country } from "./Country";
import { Currency } from "./Currency";
import { Transaction } from "./Transaction";
import { Limit } from "./Limit";
import { DepositLimit } from "./DepositLimit";
import { LoginHistory } from "./LoginHistory";
import { AccountStatusLog } from "./AccountStatusLog";
import { Comment } from "./Comment";
import { Wagering } from "./Wagering";
import { AccountTag } from "./AccountTag";
import { AccountDocument } from "./AccountDocument";

export class Account {
  id: number;

  balance: number;

  name: string;

  email: string;

  dateOfBirth: Date;

  address: string;

  city: string;

  zipcode: string;

  country: Country;

  language: Language;

  phoneNumber?: string;

  documentNumber: string;

  currency: Currency;

  emailMarketing: number;

  smsMarketing: number;

  blockedWithdrawals: number;

  blockedDeposits: boolean;

  isTest: number;

  active: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  transactions: Transaction[];

  limits: Limit[];

  depositLimits: DepositLimit[];

  loginHistory: LoginHistory[];

  statusLogs: AccountStatusLog[];

  comments: Comment[];

  wagering: Wagering;

  accountTags: AccountTag[];

  emailVerified: boolean;

  phoneVerified: boolean;

  accountDocuments?: AccountDocument[];

  accountTier: number;
}
