/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Table, Jumbotron, Card, Row, Button } from "react-bootstrap";
import { safeFormatDate } from "src/utilities/date";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";

import { Account } from "src/models/app/Account";
import { Bonus, BonusStates } from "src/models/app/Bonus";
import { RequestBonusInfoDto } from "src/models/dto/bonus.dto";

interface BonusProps {
  account: Account;
}

const AccountBonusInfo: FunctionComponent<BonusProps> = ({ account }) => {
  const [accountBonusInfo, setAccountBonusInfo] = useState<Bonus>();
  const [getDataStatus, setgetDataStatus] = useState(false);

  const fetchBonusInfo = async (): Promise<void> => {
    const response = await makeRequest<Bonus, RequestBonusInfoDto>(
      "post",
      urls.ACCOUNT_BONUS_INFO,
      {
        accountId: account.id,
      },
    );
    setAccountBonusInfo(response);
  };

  const handleBonusInfo = () => {
    return (
      <>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Account Id</th>
              <th>Status</th>
              <th>Amount to Wager</th>
              <th>Remaining Wager</th>
              <th>Redeemable Bonus</th>
              <th>Amount Won</th>
              <th>Transaction ID</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Expiry At</th>
            </tr>
          </thead>
          <tbody>
            {accountBonusInfo && (
              <tr>
                <td>{accountBonusInfo.accountId}</td>
                <td>{BonusStates[accountBonusInfo.stateId]}</td>
                <td>{accountBonusInfo.amountToWager || 0.0}</td>
                <td>{accountBonusInfo.remainingWagerAmount || 0.0}</td>
                <td>{accountBonusInfo.redeemableBonusAmount || 0.0}</td>
                <td>{accountBonusInfo.amountWon || 0.0}</td>
                <td>{accountBonusInfo.bonusAwardedTransactionId || 0.0}</td>
                <td>{safeFormatDate(accountBonusInfo.createdAt)}</td>
                <td>{safeFormatDate(accountBonusInfo.updatedAt)}</td>
                <td>{safeFormatDate(accountBonusInfo.expiryDate)}</td>
              </tr>
            )}
          </tbody>
        </Table>

        {!accountBonusInfo && (
          <Row className="d-flex justify-content-center align-items-center flex-column card-body">
            <h4>Bonus not found</h4>
          </Row>
        )}
      </>
    );
  };

  useEffect(() => {
    if (getDataStatus) {
      fetchBonusInfo();
    }
  }, [getDataStatus]);

  function bonusView() {
    return (
      <>
        <Card className="m-4">
          <Card.Header>
            <Row className="ml-1 d-flex align-items-center">
              <h3>Bonus</h3>
            </Row>
          </Card.Header>

          <Card.Body>
            <h5 className="ml-1">Bonus Info</h5>
            {handleBonusInfo()}
          </Card.Body>
        </Card>
      </>
    );
  }

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setgetDataStatus(true);
  };

  return (
    <Jumbotron className="m-4">
      {getDataStatus ? bonusView() : getDataView()}
    </Jumbotron>
  );
};

export default AccountBonusInfo;
