import React, { FunctionComponent, useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import FileUpload from "../../../components/fileUpload/FileUpload";
import { Account } from "../../../models/app/Account";

export interface ManualPaymentProps {
  onSubmit: (data: any) => void;
  account: Account;
  refreshAccount?: () => void;
}

const ManualPayment: FunctionComponent<ManualPaymentProps> = ({
  onSubmit,
  account,
}) => {
  const [amount, setAmount] = useState("");
  const [modifyWagering, setModifyWagering] = useState(false);
  const [comments, setComments] = useState("");

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onSubmit({ amount, modifyWagering, comments, type: "CREDIT" });
    setAmount("");
    setModifyWagering(false);
    setComments("");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="creditAmount">
        <Form.Label>Credit Amount</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter credit amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="addToWagering">
        <Form.Check
          type="checkbox"
          label="Add Amount to Wagering"
          checked={modifyWagering}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setModifyWagering(e.target.checked)
          }
        />
      </Form.Group>
      <Form.Group controlId="comments">
        <Form.Label>Comments</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          required
        />
      </Form.Group>
      <Card.Header>Upload Document</Card.Header>
      <FileUpload account={account} documentType="manual-payments-docs" />
      <Button type="submit">Send</Button>
    </Form>
  );
};

export default ManualPayment;
