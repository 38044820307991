/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Dayjs from "dayjs";

interface IDateRangeProps {
  setFromDate: any;
  setToDate: any;
  setApplyDateFilter: any;
  setClearDateFilter: any;
}

const DateRange: FunctionComponent<IDateRangeProps> = (
  props: IDateRangeProps,
) => {
  const [startDate, setStartDate] = useState(
    Dayjs(new Date()).format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(
    Dayjs(new Date()).format("YYYY-MM-DD"),
  );

  const handleSelect = async (value: any, type: number): Promise<void> => {
    if (type === 1) {
      setEndDate(value);
      props.setToDate(value);
    } else {
      setStartDate(value);
      props.setFromDate(value);
    }

    props.setApplyDateFilter(false);
  };

  const apply = async (): Promise<void> => {
    props.setApplyDateFilter(true);
    props.setClearDateFilter(false);
  };

  const clear = async (): Promise<void> => {
    props.setClearDateFilter(true);
    props.setApplyDateFilter(false);
  };

  return (
    <Row>
      <Col className="col-md-2">
        <Form.Control
          type="date"
          placeholder="ex. 24/05/1997"
          defaultValue={startDate}
          onChange={(e: any): void => {
            handleSelect(e.target.value, 0);
          }}
        />
      </Col>
      <Col className="col-md-2">
        <Form.Control
          type="date"
          placeholder="ex. 24/05/1997"
          defaultValue={endDate}
          onChange={(e: any): void => {
            handleSelect(e.target.value, 1);
          }}
        />
      </Col>
      <Col className="col-md-2 space-x-">
        <Button variant="primary" size="sm" onClick={apply}>
          Apply
        </Button>
        <Button className="ml-2" variant="primary" size="sm" onClick={clear}>
          Clear
        </Button>
      </Col>
    </Row>
  );
};

export default DateRange;
