/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */ export type AccountErrorCode =
  | "ACCOUNT_NOT_FOUND"
  | "ACCOUNT_DUPLICATE_DOCUMENT_NUMBER"
  | "ACCOUNT_DUPLICATE_EMAIL"
  | "ACCOUNT_ALREADY_DELETED";

export type GameErrorCode =
  | "GAME_NOT_FOUND"
  | "GAME_DUPLICATE_CODE"
  | "GAME_DUPLICATE_EXTERNAL_ID"
  | "GAME_ALREADY_DELETED";

export type AuthErrorCode =
  | "INCORRECT_CREDENTIALS"
  | "UNAUTHORIZED"
  | "PASSWORD_NOT_SET";

export type TokenErrorCode =
  | "INVALID_SET_PASSWORD_TOKEN"
  | "INVALID_SESSION_TOKEN"
  | "MISSING_SESSION_TOKEN";

export type GameCategoryErrorCode = "GAME_CATEGORY_NOT_FOUND";

export type CommentErrorCode = "COMMENT_NOT_FOUND" | "COMMENT_ALREADY_DELETED";

export type SpelpausErrorCode = "INVALID_DOCUMENT_NUMBER";

export type LimitErrorCode = "LIMIT_NOT_FOUND" | "LIMIT_ALREADY_DELETED";

export type YoErrorCode =
  | "NETWORK_ERROR"
  | "BAD_REQUEST"
  | "TYPEORM_QUERY_FAILED"
  | "TYPE_ERROR"
  | "UNKNOWN_ERROR"
  | "EXTERNAL_API_ERROR"
  | AccountErrorCode
  | GameErrorCode
  | AuthErrorCode
  | TokenErrorCode
  | SpelpausErrorCode
  | LimitErrorCode
  | CommentErrorCode
  | GameCategoryErrorCode;

export class YoErrorResponse {
  code: YoErrorCode;
  message: string;
  stack?: string;
}
