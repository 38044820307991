import React, { FunctionComponent } from "react";
import { safeFormatDate } from "../../../utilities/date";
import { ResponseGetCommentDto } from "src/models/dto/comment.dto";

export interface IAccountCommentItemProps {
  commentDto: ResponseGetCommentDto;
}

export const AccountCommentItem: FunctionComponent<IAccountCommentItemProps> = (
  props: IAccountCommentItemProps,
) => {
  const isDeleted = (comment: ResponseGetCommentDto): boolean =>
    comment.comment.deletedAt !== null;

  return (
    <tr key={props.commentDto.comment.id}>
      <td>
        {props.commentDto.comment.id}
        {isDeleted(props.commentDto) ? " (Deleted)" : ""}
      </td>
      <td>
        {props.commentDto.backOfficeAccount
          ? props.commentDto.backOfficeAccount.name
          : ""}
        {props.commentDto.backOfficeAccount ? (
          <>(ID: {props.commentDto.backOfficeAccount.id})</>
        ) : (
          ""
        )}
      </td>
      <td>{props.commentDto.comment.type}</td>
      <td>{props.commentDto.comment.action}</td>
      <td>{props.commentDto.comment.comment}</td>
      <td>{safeFormatDate(props.commentDto.comment.createdAt)}</td>
      <td>{safeFormatDate(props.commentDto.comment.updatedAt)}</td>
    </tr>
  );
};
