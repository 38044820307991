export enum BonusStates {
  INITIATED = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  EXPIRED = 4,
  CANCELLED = 5,
}

export class Bonus {
  accountId: number;

  stateId: number;

  amountToWager: number;

  remainingWagerAmount: number;

  redeemableBonusAmount: number;

  amountWon: number;

  bonusAwardedTransactionId: number;

  createdAt: Date;

  updatedAt: Date;

  expiryDate: Date;
}
