/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import { Table, Button, Card } from "react-bootstrap";
import urls from "src/utilities/urls";
import { makeRequest } from "src/utilities/axio.helper";
import {
  RequestGetCommentDto,
  ResponseGetCommentDto,
  ResponseGetComments,
} from "src/models/dto/comment.dto";
import { AccountCommentItem } from "./AccountCommentItem";
import { CreateAccountCommentModal } from "./CreateAccountCommentModal";
import { Account } from "src/models/app/Account";

interface IAccountCommentsProps {
  account: Account;
  refresh: boolean;
}

const AccountComments: FunctionComponent<IAccountCommentsProps> = (
  props: IAccountCommentsProps,
) => {
  const [comments, setComments] = useState<ResponseGetCommentDto[]>([]);
  const [getDataStatus, setgetDataStatus] = useState(false);

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setgetDataStatus(true);
  };

  const fetchData = async (): Promise<void> => {
    const response: ResponseGetComments = await makeRequest<
      ResponseGetComments,
      RequestGetCommentDto
    >("post", urls.COMMENTS_LIST, {
      where: { accountId: [Number(props.account.id)] },
      withBackOfficeAccount: true,
    });
    setComments(response.comments);
  };

  useEffect(() => {
    if (getDataStatus) {
      fetchData();
    }
  }, [getDataStatus, props.refresh]);

  const showCreateCommentModal = (): void => setShowCreateModal(true);
  const hideCreateCommentModal = (): void => setShowCreateModal(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const onSaveComment = (): Promise<void> => fetchData();

  const getCreateCommentModal = (): React.ReactChild => {
    if (showCreateModal && props.account) {
      return (
        <CreateAccountCommentModal
          account={props.account}
          removeModal={hideCreateCommentModal}
          onSave={onSaveComment}
        />
      );
    }

    return <></>;
  };

  const commentView = () => {
    return (
      <>
        <Card>
          <Card.Header>
            Comments
            <Button
              className="float-right"
              variant="primary"
              onClick={showCreateCommentModal}
            >
              Create Comment
            </Button>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Comment Creator</th>
                  <th>Type</th>
                  <th>Action</th>
                  <th>Comment</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {comments.map(
                  (
                    commentDto: ResponseGetCommentDto,
                    index: number,
                  ): React.ReactChild => {
                    return (
                      <AccountCommentItem key={index} commentDto={commentDto} />
                    );
                  },
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {getCreateCommentModal()}
      </>
    );
  };

  return <>{getDataStatus ? commentView() : getDataView()}</>;
};

export default AccountComments;
