import React, { Component } from "react";
import { Jumbotron } from "react-bootstrap";
import LoginHistoryList from "./LoginHistoryList/LoginHistoryList";

export class LoginHistoryPage extends Component {
  render() {
    return (
      <div>
        <Jumbotron className="m-4">
          <h3>Login History</h3>
          <LoginHistoryList />
        </Jumbotron>
      </div>
    );
  }
}
