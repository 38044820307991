import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import { ManuallyAddedGame } from "src/models/app/ManuallyAddedGame";
import toastr from "toastr";
import { makeRequest } from "../../../utilities/axio.helper";
import config from "src/config";
import urls from "src/utilities/urls";
import { useHistory } from "react-router-dom";

interface BasicProvider {
  id: number;
  name: string;
}

const AddGameManuallyPage: FunctionComponent = () => {
  const [providers, setProviders] = useState<BasicProvider[]>([]);
  const [manualGame, setManualGame] = useState<ManuallyAddedGame>(
    new ManuallyAddedGame(),
  );
  const history = useHistory();

  useEffect(() => {
    const getProviders = async (): Promise<void> => {
      try {
        const response = await makeRequest<BasicProvider[], BasicProvider[]>(
          "post",
          urls.PROVIDERS_MANUAL_LIST,
        );
        setProviders(response);
      } catch (err) {
        toastr.error((err as any).message);
      }
    };
    getProviders();
  }, []);

  useEffect(() => {
    const game = { ...manualGame };
    setManualGame({
      ...game,
      providerId: providers[0]?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  const addGame = async (): Promise<void> => {
    try {
      await makeRequest(
        "post",
        `${config.API_URL}${urls.GAMES_ADD_MANUAL}`,
        {
          ...manualGame,
        },
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );

      toastr.success(
        `${manualGame.name} GAME added for provider:${manualGame.providerId}`,
      );
      history.push("/games");
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const renderProviders = () => {
    return providers.map((provider: BasicProvider): React.ReactChild => {
      return (
        <option key={provider.id} value={provider.id}>
          {provider.name}
        </option>
      );
    });
  };

  const checkData = () => {
    if (!manualGame.name || !manualGame.externalId || !manualGame.providerId) {
      toastr.error(`Fill in all fields`);
      return false;
    }

    return true;
  };
  const handleAddGame = () => {
    if (checkData()) {
      addGame();
    } else {
      return;
    }
  };

  return (
    <div className="d-block p-5">
      <Card>
        <Card.Header>Add Game Manually</Card.Header>
        <Card.Body>
          <Form>
            <Form.Row style={{ width: "50%" }}>
              <Form.Group as={Col} controlId="formAddManualGameProvider">
                <Form.Label>Provider</Form.Label>
                <Form.Control
                  placeholder="Select Provider"
                  as="select"
                  custom
                  defaultValue={providers[0]?.name}
                  onChange={(option: ChangeEvent<HTMLSelectElement>): void => {
                    setManualGame({
                      ...manualGame,
                      providerId: Number(option.target.value),
                    });
                  }}
                >
                  {renderProviders()}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formAddManualGameName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Game Name"
                  defaultValue={manualGame?.name}
                  onChange={(option: ChangeEvent<HTMLInputElement>): void => {
                    setManualGame({
                      ...manualGame,
                      name: option.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGameCategories">
                <Form.Label>External ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Game External ID"
                  defaultValue={manualGame?.externalId}
                  onChange={(option: ChangeEvent<HTMLInputElement>): void => {
                    setManualGame({
                      ...manualGame,
                      externalId: option.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                style={{ width: "50%", marginBottom: "3rem" }}
                controlId="formAddManualGameActive"
              >
                <Form.Label>Set Game As Active?</Form.Label>
                <Form.Control
                  style={{ width: "24px", marginLeft: "50px" }}
                  type="checkbox"
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    if (manualGame) {
                      if (evt.target) {
                        const gameActive: number = evt.target.checked ? 1 : 0;
                        setManualGame({
                          ...manualGame,
                          active: gameActive,
                        });
                      }
                    }
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Button
              style={{ height: "3rem", width: "15%" }}
              onClick={handleAddGame}
              variant="primary"
            >
              Add Game
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddGameManuallyPage;
