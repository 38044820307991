import React, { FunctionComponent } from "react";
import { Col, Form } from "react-bootstrap";
interface IKYCItemProps {
  value: boolean;
  name: string;
  onChange: (event: any) => void;
}

export const KYCItem: FunctionComponent<IKYCItemProps> = (
  props: IKYCItemProps,
) => {
  return (
    <Col>
      <Form>
        <Form.Switch
          id={`${props.name}-switch`}
          label={props.name}
          checked={props.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(event.target.checked);
          }}
          readOnly={true}
        />
      </Form>
    </Col>
  );
};
