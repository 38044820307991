import React, { FunctionComponent, useEffect, useState } from "react";
import {
  PaymentProvider,
  UpdatePaymentProvider,
} from "src/models/dto/payment-provider.dto";
import { makeRequest } from "src/utilities/axio.helper";
import config from "src/config";
import OverlayLoader from "src/components/overlay/Overlay";
import { Button, Jumbotron, Table } from "react-bootstrap";
import toastr from "toastr";

export const PaymentProvidersList: FunctionComponent = () => {
  const [paymentProviders, setPaymentProviders] = useState<PaymentProvider[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const fetchpaymentProviders: () => Promise<void> = async () => {
    try {
      const response = await makeRequest<PaymentProvider[]>(
        "get",
        `${config.BACKEND_API_URL}payment/providers?code=trustly-swish`,
        undefined,
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );
      setPaymentProviders(response);
    } catch (error) {
      toastr.error((error as any).message);
    }
  };

  const handleActivate = async (id: number, active: boolean): Promise<void> => {
    setLoading(true);
    try {
      await makeRequest<UpdatePaymentProvider, UpdatePaymentProvider>(
        "put",
        `${config.BACKEND_API_URL}payment/providers/${id}`,
        { active },
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );
      await fetchpaymentProviders();
    } catch (error) {
      toastr.error((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchpaymentProviders();
  }, []);
  return (
    <>
      <OverlayLoader isLoading={loading} />
      <Jumbotron className="m-4">
        <h3 className="mb-3">Payment Providers</h3>
        {paymentProviders && paymentProviders.length > 0 && (
          <Table striped bordered hover className="transactionTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Payment Provider Name</th>
                <th>Payment Provider Code</th>
                <th>Payment Provider Active</th>
              </tr>
            </thead>
            <tbody>
              {paymentProviders.map((provider, ix) => (
                <tr key={ix}>
                  <td>{provider.id}</td>
                  <td>{provider.name}</td>
                  <td>{provider.code}</td>
                  <td>{Number(provider.active)}</td>
                  <td>
                    <div className="d-flex justify-content-center flex-column">
                      <Button
                        variant="success"
                        onClick={() =>
                          handleActivate(provider.id, !provider.active)
                        }
                        className="mb-2"
                      >
                        {provider.active ? "Deactivate" : "Activate"}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Jumbotron>
    </>
  );
};
