/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { safeFormatDate } from "src/utilities/date";
import { makeRequest } from "src/utilities/axio.helper";
import { RequestGetAccountManualAdjustDto } from "src/models/dto/transaction.dto";
import urls from "src/utilities/urls";

import { ManualAdjustment } from "src/models/app/ManualAdjustment";

interface ManualAdjustmentAccount {
  accountId: number;
  getDataStatus: boolean;
}

const ManualAdjustments: FunctionComponent<ManualAdjustmentAccount> = ({
  accountId,
  getDataStatus,
}) => {
  const [accountManualAdjustments, setaccountManualAdjustments] =
    useState<ManualAdjustment[]>();

  const fetchData = async (): Promise<void> => {
    const response = await makeRequest<
      ManualAdjustment[],
      RequestGetAccountManualAdjustDto
    >("post", urls.ACCOUNT_MANUAL_ADJUSTMENTS, {
      accountId: accountId,
    });

    setaccountManualAdjustments(response);
  };

  useEffect(() => {
    if (getDataStatus) {
      fetchData();
    }
  }, [getDataStatus]);

  return (
    <>
      <Table striped bordered hover className="mt-5">
        <thead>
          <tr>
            <th>Manual Adjustment ID</th>
            <th>TransactionID</th>
            <td>Comment</td>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {accountManualAdjustments &&
            accountManualAdjustments.map(
              (manualAdjustment: ManualAdjustment) => {
                return (
                  <tr key={manualAdjustment.id}>
                    <td>{manualAdjustment.id}</td>
                    <td>{manualAdjustment.transactionId}</td>
                    <td>{manualAdjustment.comment}</td>
                    <td>{safeFormatDate(manualAdjustment.createdAt)}</td>
                  </tr>
                );
              },
            )}
        </tbody>
      </Table>
    </>
  );
};

export default ManualAdjustments;
