import DotEnvFlow from "dotenv-flow";

const config = DotEnvFlow.config();

if (!config) {
  // eslint-disable-next-line no-console
  console.error("!!!!!! .env file not found !!!!!!");
}

export const Config = {
  API_URL:
    process.env.REACT_APP_API_URL ||
    "https://api-backoffice-staging.frejacasino.se",
  ENVIRONMENT: process.env.NODE_ENV,
  SESSION_TOKEN_IDENTIFIER: "session-token",
  // Basic Authentication for admin routes
  API_AUTH: {
    USERNAME: "FRADMIN",
    PASSWORD: "Fr89g4A0uykan99!",
  },
  AUTH: {
    LOGIN_DISABLED: false,
  },
  limits: {
    session: [24, 168, 720, 2160, 8760],
  },
  FRONT_API_URL:
    process.env.REACT_APP_FRONT_URL || "https://staging.frejacasino.se/",
  BACKEND_API_URL:
    process.env.REACT_APP_BACKEND_URL || "https://api-staging.frejacasino.se/",
  PORT: process.env.PORT || 5001,
  BACKOFFICE_ADMIN_PASSWORD: "bc46e5x4-8f46-4c93-adb8-3f45a6a120bd",
  GOOGLE_AUTH_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ||
    "391530722260-tl9mmsoaj2ag1kl4ocfpn9a3oi4707ft.apps.googleusercontent.com",
  GCP_STORAGE_BUCKET_URL:
    process.env.GCP_STORAGE_BUCKET_URL || "https://storage.cloud.google.com/",
  
  BACKOFFICE_FRONTEND_URLS: {
    EPIC: process.env.EPIC_BO_URL || "https://backoffice.casinoepic.se/",
    GAMI: process.env.GAMI_BO_URL || "https://backoffice.casinogami.se/",
    LOKE: process.env.LOKE_BO_URL || "https://backoffice.lokecasino.se/",
    FREJA: process.env.FREJA_BO_URL || "https://backoffice.frejacasino.se/",
  }
};

export default Config;
