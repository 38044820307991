import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import { makeRequest } from "../../../utilities/axio.helper";
import config from "src/config";
import urls from "src/utilities/urls";
import { ManuallyAddedJackpotGame } from "src/models/app/ManuallyAddedJackpotGame";
import { useHistory } from "react-router-dom";

const AddGameJackpotManuallyPage: FunctionComponent = () => {
  const [jackpotGame, setJackpotGame] = useState<ManuallyAddedJackpotGame>(
    new ManuallyAddedJackpotGame(),
  );
  const history = useHistory();

  const addJackpotGame = async (): Promise<void> => {
    try {
      await makeRequest(
        "post",
        `${config.API_URL}${urls.JACKPOT_GAMES_ADD_MANUAL}`,
        {
          ...jackpotGame,
        },
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );

      toastr.success(
        `${jackpotGame.moduleId} MODULE ID added for GAME ID:${jackpotGame.gameId}`,
      );
      history.push("/games");
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  return (
    <div className="d-block p-5">
      <Card>
        <Card.Header>Add Jackpot Game Manually</Card.Header>
        <Card.Body>
          <Form>
            <Alert key={"danger"} variant={"danger"}>
              <h4>
                Only use this funcionallity for a jackpot game that has already
                been added!
              </h4>
            </Alert>
            <Form.Row>
              <Form.Group as={Col} controlId="formAddManualGameName">
                <Form.Label>Game ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Game ID"
                  onChange={(option: ChangeEvent<HTMLInputElement>): void => {
                    setJackpotGame({
                      ...jackpotGame,
                      gameId: parseInt(option.target.value),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGameCategories">
                <Form.Label>Module ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Module ID"
                  onChange={(option: ChangeEvent<HTMLInputElement>): void => {
                    setJackpotGame({
                      ...jackpotGame,
                      moduleId: parseInt(option.target.value),
                    });
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Button
              style={{ height: "3rem", width: "15%" }}
              variant="primary"
              onClick={addJackpotGame}
            >
              Add Jackpot Game
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddGameJackpotManuallyPage;
