import React, { FunctionComponent, useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { GameCategory } from "src/models/app/GameCategory";
import { Game } from "src/models/app/Game";
import { GameCategoryUpdateModal } from "./GameCategoryUpdateModal";
import { safeFormatDate } from "src/utilities/date";

export interface IGameCategoryListItem {
  gameCategory: GameCategory;
  game: Game;
  onUpdated: () => void;
}

export const GameCategoryListItem: FunctionComponent<IGameCategoryListItem> = (
  props: IGameCategoryListItem,
) => {
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const showUpdateLimitModal = (): void => setShowUpdateModal(true);
  const hideUpdateLimitModal = (): void => setShowUpdateModal(false);

  const getUpdateLimitModal = (): React.ReactChild => {
    if (showUpdateModal && props.gameCategory) {
      return (
        <GameCategoryUpdateModal
          gameCategory={props.gameCategory}
          removeModal={hideUpdateLimitModal}
          onUpdate={props.onUpdated}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <tr key={props.game.id}>
        <td>{props.game.name}</td>
        <td>{props.gameCategory.category.name}</td>
        <td>{props.gameCategory.weight}</td>
        <td>{safeFormatDate(props.gameCategory.createdAt)}</td>
        <td>
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button variant="info" onClick={showUpdateLimitModal}>
              Update
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      {getUpdateLimitModal()}
    </>
  );
};
