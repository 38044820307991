/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Table, Jumbotron, Card, Button } from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";
import { safeFormatDate } from "../../../utilities/date";

export interface IStatusLogsProps {
  account: Account;
  refreshAccount?: () => void;
  documentType?: string;
}

interface StatusLog {
  statusType: string;
  statusNumber: number;
  reason: string | null;
  createdAt: Date;
}

const StatusLogs: FunctionComponent<IStatusLogsProps> = (
  props: IStatusLogsProps,
) => {
  const [statusLogs, setStatusLogs] = useState<StatusLog[]>([]);

  const [getDataStatus, setGetDataStatus] = useState(false);

  const fetchData = async (): Promise<void> => {
    const response = await makeRequest<StatusLog[]>("post", urls.STATUS_LOGS, {
      accountId: props.account.id,
    });

    setStatusLogs(response);
  };

  useEffect(() => {
    if (getDataStatus) {
      fetchData();
    }
  }, [getDataStatus]);

  const getStatusLogs = () => {
    return (
      <>
        <h2 style={{ marginLeft: "2rem" }}>Status Logs</h2>
        <Card className="m-4">
          <Card.Body>
            <Table responsive striped bordered hover className="mt-5">
              <thead>
                <tr>
                  <th>{"Status"}</th>
                  <th>{"Type"}</th>
                  <th>{"Reason"}</th>
                  <th>{"Created at"}</th>
                </tr>
              </thead>
              <tbody>
                {statusLogs.map((log, index) => (
                  <tr key={index}>
                    <td>{log.statusNumber === 1 ? "ENABLE" : "DISABLE"}</td>
                    <td>{log.statusType}</td>
                    <td>{log.reason}</td>
                    <td>{safeFormatDate(log.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  };

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setGetDataStatus(true);
  };

  return (
    <>
      <Jumbotron className="m-4">
        {getDataStatus ? getStatusLogs() : getDataView()}
      </Jumbotron>
    </>
  );
};

export default StatusLogs;
