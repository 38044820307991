export const getNameOfStatus = (code: number | undefined) => {
  let message: string;
  let description: string;
  switch (code) {
    case 1:
      message = "INITIATED";
      description = "When the bonus is created but not yet active";
      break;
    case 2:
      message = "ACTIVE";
      description = "When the bonus is active";
      break;
    case 3:
      message = "COMPLETED";
      description =
        "When the player has completed the bonus i.e. all his/her winning are reflected in the wallet";
      break;
    case 4:
      message = "EXPIRED";
      description = "When the bonus is expired";
      break;
    case 5:
      message = "CANCELLED";
      description = "When the bonus is cancelled by the user or lost";
      break;
    default:
      message = "Account don't have a status code";
      description = "No status code in the account";
      break;
  }

  return { message, description };
};
