/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Account } from "./Account";

export enum CommentType {
  RG = "Responsible Gaming",
  AML = "Anti-Money Laundering",
  GENERIC = "Generic",
  KYC = "KYC/Verification",
}

export enum CommentAction {
  ACCOUNT_CLOSED = "Account Closed",
  WD_BLOCKED = "Withdrawal Blocked",
  DEP_BLOCKED = "Deposit Blocked",
  EXC_AGENT = "Self Excluded by agent",
  LIMITS_ADJ = "Limits Adjusted",
  NO_ACTION = "No Action Taken",
}

export class Comment {
  id: number;

  comment: string;

  type: string;

  action: string;

  account: Account;

  backOfficeAccountId: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;
}
