import React, { FunctionComponent } from "react";
import { Spinner, SpinnerProps } from "react-bootstrap";

const SpinnerComp: FunctionComponent<SpinnerProps> = () => {
  return (
    <>
      <h1 className="mb-4">Getting Stuff....</h1>
      <Spinner animation="border"></Spinner>
    </>
  );
};

export default SpinnerComp;
