/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Form,
  Button,
  Jumbotron,
  Card,
  Accordion,
} from "react-bootstrap";
import { safeFormatDate } from "src/utilities/date";
import { makeRequest } from "src/utilities/axio.helper";
import {
  RequestGetAccountTransactionsDto,
  GetAccountMonetaryTransactionsWhere,
} from "src/models/dto/transaction.dto";
import urls from "src/utilities/urls";

import ManualAdjustments from "./ManualAdjustments";
import { Account } from "src/models/app/Account";
import { Transaction, TransactionStatus } from "src/models/app/Transaction";
import PDFExport from "src/components/exports/PDFExport";
import CSVExport from "src/components/exports/CSVExport";
import PaymentAcc from "./PaymentAccount";

interface IAccountTransactionsProps {
  account: Account;
}

const AccountTransactions: FunctionComponent<IAccountTransactionsProps> = (
  props: IAccountTransactionsProps,
) => {
  const [transactionTotalsDto, setTransactionTotalsDto] = useState<number>();

  const [accountTransactions, setaccountTransactions] =
    useState<Transaction[]>();

  const [fullAccountTransactions, setFullAccountTransactions] =
    useState<Transaction[]>();

  const [exportBody, setExportBody] = useState<(string | number)[][]>();
  const [typeInput, setTypeInput] = useState<string>("");
  const [statusInput, setStatusInput] = useState<string>("");
  const [createdAtInput, setCreatedAtInput] = useState<string>("");
  const [getDataStatus, setgetDataStatus] = useState(false);

  const fetchData = async (
    where?: GetAccountMonetaryTransactionsWhere,
  ): Promise<void> => {
    const response = await makeRequest<
      { transactions: Transaction[]; totalTransactions: number },
      RequestGetAccountTransactionsDto
    >("post", urls.ACCOUNT_MONETARY_TRANSACTIONS, {
      where,
      accountId: props.account.id,
      limit: paginationShowAmount,
      offset: (paginationIndex - 1) * paginationShowAmount,
    });

    setaccountTransactions(response.transactions);

    setTransactionTotalsDto(response.totalTransactions);

    const fullTransactionResponse = await makeRequest<
      { transactions: Transaction[]; totalTransactions: number },
      RequestGetAccountTransactionsDto
    >("post", urls.ACCOUNT_MONETARY_TRANSACTIONS, {
      where,
      accountId: props.account.id,
    });

    setFullAccountTransactions(fullTransactionResponse.transactions);
  };

  useEffect(() => {
    if (fullAccountTransactions !== undefined) {
      setExportBody(
        fullAccountTransactions!.map((trans) => [
          trans.type,
          trans.status,
          trans.balanceBefore,
          trans.balanceAfter,
          trans.debit,
          trans.credit,
          safeFormatDate(trans.createdAt),
          safeFormatDate(trans.updatedAt),
        ]),
      );
    }
  }, [fullAccountTransactions]);

  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationShowAmount] = useState<number>(30);

  const getPagination = (totalTransactions: number): React.ReactChild => {
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;
    const paginationTotalPages = Math.ceil(
      totalTransactions / paginationShowAmount,
    );

    const items = [];

    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    if (paginationTotalPages <= 1) {
      return <></>;
    }

    return (
      <Pagination className="mr-4 mt-5 mb-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
          }}
        />
      </Pagination>
    );
  };

  const fillWhereAndGet = () => {
    const where: GetAccountMonetaryTransactionsWhere =
      new GetAccountMonetaryTransactionsWhere();
    where.status = statusInput;
    where.type = typeInput;
    where.createdAt = createdAtInput;
    fetchData(where);
  };

  useEffect(() => {
    if (getDataStatus) {
      fillWhereAndGet();
    }
  }, [getDataStatus, paginationIndex]);

  useEffect(() => {
    if (getDataStatus) {
      if (statusInput || typeInput || createdAtInput) {
        setPaginationIndex(1);
      }
      fillWhereAndGet();
    }
  }, [statusInput, typeInput, createdAtInput]);

  const getTransactions = (
    transactions: Transaction[],
    totalTransactions: number,
  ) => {
    return (
      <>
        {getPagination(totalTransactions)}
        <Table striped bordered hover className="mt-5">
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Status</th>
              <th>Balance Before</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance After</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction: Transaction) => {
              return (
                <tr key={transaction.id}>
                  <td>{transaction.id}</td>
                  <td>{transaction.type}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.balanceBefore}</td>
                  <td>{transaction.debit}</td>
                  <td>{transaction.credit}</td>
                  <td>{transaction.balanceAfter}</td>
                  <td>{safeFormatDate(transaction.createdAt)}</td>
                  <td>{safeFormatDate(transaction.updatedAt)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const typeSelect = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Type</Form.Label>
          <Form.Control
            as="select"
            value={typeInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = String(event.target.value);
              setTypeInput(input);
            }}
          >
            <option value={""}>Select Type</option>
            <option value={"DEPOSIT"}>Deposit</option>
            <option value={"WITHDRAW"}>Withdraw</option>
            <option value={"MANUAL_ADJUSTMENT"}>Manual Adjustment</option>
            <option value={"CORRECTION"}>Correction</option>
          </Form.Control>
        </Form.Group>
      </>
    );
  };

  const statusSelect = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Status</Form.Label>
          <Form.Control
            as="select"
            value={statusInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = String(event.target.value);
              setStatusInput(input);
            }}
          >
            <option key={""} value={""}>
              Select Status
            </option>
            {Object.keys(TransactionStatus).map((e) => {
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      </>
    );
  };

  const createdAtPicker = () => {
    return (
      <>
        <Form.Group>
          <Form.Label>Filter by Created At</Form.Label>
          <Form.Control
            type="date"
            value={createdAtInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const input = String(event.target.value);
              setCreatedAtInput(input);
            }}
          ></Form.Control>
        </Form.Group>
      </>
    );
  };

  const accountInfo =
    props.account.name +
    "\t" +
    props.account.email +
    "\t" +
    safeFormatDate(new Date(Date.now()));

  const headers = [
    "Type",
    "Status",
    "Balance Before",
    "Balance After",
    "Debit",
    "Credit",
    "Created At",
    "Updated At",
  ];

  const transactionView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Filter
            </Accordion.Toggle>
            <CSVExport
              title={accountInfo}
              head={headers}
              body={exportBody}
              outputFile={"Transaction"}
            />
            <PDFExport
              title={accountInfo}
              head={headers}
              body={exportBody}
              outputFile={"Transaction"}
            />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={fetchData}>
              {typeSelect()}
              {statusSelect()}
              {createdAtPicker()}
              <Button
                variant="dark"
                className="ml-2"
                onClick={() => {
                  setTypeInput("");
                  setStatusInput("");
                  setCreatedAtInput("");
                }}
              >
                Clear
              </Button>
            </Form>
            {transactionTotalsDto && (
              <>
                {getTransactions(
                  accountTransactions as Transaction[],
                  transactionTotalsDto,
                )}
              </>
            )}
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Manual Adjustments</Card.Header>
          <Card.Body>
            <ManualAdjustments
              accountId={props.account.id}
              getDataStatus={getDataStatus}
            />
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Header>Account Payments</Card.Header>
          <Card.Body>
            <PaymentAcc
              accountId={props.account.id}
              getDataStatus={getDataStatus}
            />
          </Card.Body>
        </Card>
      </>
    );
  };

  const getDataView = () => {
    return (
      <>
        <Card className="m-4">
          <Card.Body className="text-center">
            <Button variant="primary" onClick={onClickGetDataButton}>
              Get Data
            </Button>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onClickGetDataButton = () => {
    setgetDataStatus(true);
  };

  return (
    <>
      <Jumbotron className="m-4">
        {getDataStatus ? transactionView() : getDataView()}
      </Jumbotron>
    </>
  );
};

export default AccountTransactions;
