import { AccountDocument } from "./AccountDocument";

export enum AccountDocumentTypeCodes {
  UNKNOWN = "unknown",
  PROOF_OF_IDENTITY = "proof-of-identity",
  PROOF_OF_ADDRESS = "proof-of-address",
  PROOF_OF_FUNDS = "proof-of-funds",
  SOURCE_OF_FUNDS = "source-of-funds",
}

export class AccountDocumentType {
  id: number;

  name: string;

  code: AccountDocumentTypeCodes;

  description: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  accountDocuments?: AccountDocument[];
}
