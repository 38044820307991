import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";
import DocumentList from "./DocumentsList";

const DocumentsPage: FunctionComponent = () => {
  return (
    <div>
      <Switch>
        <Route path="/documents">
          <Jumbotron className="m-4">
            <h3>Documents List</h3>
            <DocumentList></DocumentList>
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default DocumentsPage;
