import React, { FunctionComponent } from "react";
import feather from "feather-icons";

interface IIconProps {
  name: string;
  width?: string;
  height?: string;
}

const Icon: FunctionComponent<IIconProps> = (props: IIconProps) => {
  return (
    <span>
      <span
        dangerouslySetInnerHTML={{
          __html: feather.icons[props.name].toSvg({
            class: "display: inline, stroke: currentColor, stroke-width: 2",
            width: props.width ? props.width : "1em",
            height: props.height ? props.height : "1em",
          }),
        }}
      />
    </span>
  );
};

export default Icon;
