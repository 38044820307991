/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  Pagination,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { Account } from "../../../models/app/Account";
import urls from "src/utilities/urls";
import DayJS from "dayjs";
import {
  ListAccountsWhereDto,
  ResponseListAccountsDto,
  RequestListAccountsDto,
} from "src/models/dto/account.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { TestAccountCreateModal } from "../TestAccountCreateModal";
import AccountTable from "./AccountTable";
import DateInput from "src/components/inputs/DateInput";

interface IAccountListProps {
  deleteAccount: (accountId: number) => Promise<void>;
}

interface IFilterValues {
  name?: string[];
  email?: string[];
  phoneNumber?: string[];
  id?: number[];
  documentNumber?: string[];
  showDeleted: boolean;
  showActive: boolean;
}

const AccountList: FunctionComponent<IAccountListProps> = () => {
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationShowAmount] = useState<number>(7);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [filterValues, setFilterValues] = useState<IFilterValues>({
    showActive: false,
    showDeleted: true,
  });
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const [fromDate, setFromDate] = useState(
    DayJS(new Date()).format("YYYY-MM-DD"),
  );
  const [toDate, setToDate] = useState(DayJS(new Date()).format("YYYY-MM-DD"));
  const [applyDateFilter, setApplyDateFilter] = useState<boolean>(false);
  const [clearDateFilter, setClearDateFilter] = useState<boolean>(false);

  const refreshAccounts = async (): Promise<void> => {
    try {
      const dateFilters = {
        email: filterValues.email,
        phoneNumber: filterValues.phoneNumber,
        name: filterValues.name,
        id: filterValues.id,
        documentNumber: filterValues.documentNumber,
        withDeleted: filterValues.showDeleted,
        startDate: DayJS(fromDate).format("YYYY-MM-DD"),
        endDate: DayJS(toDate).format("YYYY-MM-DD"),
      };

      const normalFilters = {
        email: filterValues.email,
        phoneNumber: filterValues.phoneNumber,
        name: filterValues.name,
        id: filterValues.id,
        documentNumber: filterValues.documentNumber,
        withDeleted: filterValues.showDeleted,
      };

      const where: ListAccountsWhereDto = applyDateFilter
        ? dateFilters
        : normalFilters;

      if (filterValues.showActive) {
        where.active = 1;
      } else {
        where.active = 0;
      }

      const accountsResponse: ResponseListAccountsDto = await makeRequest<
        ResponseListAccountsDto,
        RequestListAccountsDto
      >("post", urls.ACCOUNTS_LIST, {
        where,
        offset: paginationShowAmount * (paginationIndex - 1),
        limit: paginationShowAmount,
      });

      setAccounts(accountsResponse.accounts);

      setPaginationTotalPages(
        Math.ceil(accountsResponse.totalAccounts / paginationShowAmount),
      );
    } catch (err) {
      toastr.error((err as any).message);
    }
  };
  useEffect(() => {
    setPaginationIndex(1);
    refreshAccounts();
  }, [filterValues]);

  useEffect(() => {
    refreshAccounts();
  }, [paginationShowAmount, paginationIndex]);

  useEffect(() => {
    if (applyDateFilter) {
      refreshAccounts();
    }
  }, [applyDateFilter]);

  useEffect(() => {
    if (clearDateFilter) {
      refreshAccounts();
    }
  }, [clearDateFilter]);

  useEffect(() => {
    refreshAccounts();
  }, []);

  const getPagination = (): React.ReactChild => {
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;

    const items = [];
    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    return (
      <Pagination className="m-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
            getPagination();
          }}
        />
      </Pagination>
    );
  };

  const showCreateAccountModal = (): void => setShowCreateModal(true);
  const hideCreateAccountModal = (): void => setShowCreateModal(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSaveAccount = (account: Account): Promise<void> => refreshAccounts();

  const getCreateAccountModal = (): React.ReactChild => {
    if (showCreateModal) {
      return (
        <TestAccountCreateModal
          removeModal={hideCreateAccountModal}
          onSave={onSaveAccount}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <Button onClick={showCreateAccountModal}>Create Test Account</Button>

      <Accordion defaultActiveKey="0">
        <Card className="m-4" style={{ overflow: "unset" }}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Filter
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Form
                onSubmit={(e: React.FormEvent<HTMLElement>): void =>
                  e.preventDefault()
                }
              >
                <Form.Group controlId="id">
                  <Form.Label>Id Filter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Example: 12, 15"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      let id = undefined;
                      if (evt.target.value) {
                        id = evt.target.value;
                      }

                      let ids: number[] | undefined = undefined;
                      if (id) {
                        ids = id?.split(",").map((id) => Number(id));
                      }

                      setFilterValues({
                        ...filterValues,
                        id: ids,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="documentNumber">
                  <Form.Label>Document No. Filter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Example: 200012255733, 198112088359"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      let documentNumber = undefined;
                      if (evt.target.value) {
                        documentNumber = evt.target.value;
                      }

                      let documentNumbers: string[] | undefined = undefined;

                      if (documentNumber) {
                        documentNumbers = documentNumber
                          ?.split(",")
                          .map(
                            (documentNumber) =>
                              "%" + documentNumber.trim() + "%",
                          );
                      }

                      setFilterValues({
                        ...filterValues,
                        documentNumber: documentNumbers,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Label>Name Filter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Example: Jack, Jill, Up, The,Hill"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      let name = undefined;
                      if (evt.target.value) {
                        name = evt.target.value;
                      }

                      let names: string[] | undefined = undefined;

                      if (name) {
                        names = name
                          ?.split(",")
                          .map((name) => "%" + name.trim() + "%");
                      }

                      setFilterValues({
                        ...filterValues,
                        name: names,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email Filter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Example: user.test@yobetit.com, email, @gmail.com"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      let email = undefined;
                      if (evt.target.value) {
                        email = evt.target.value;
                      }

                      let emails: string[] | undefined = undefined;

                      if (email) {
                        emails = email
                          ?.split(",")
                          .map((email) => "%" + email.trim() + "%");
                      }
                      setFilterValues({
                        ...filterValues,
                        email: emails,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="phone">
                  <Form.Label>Phone Filter</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Example: 771763236, 756793456, 771553355"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      let phoneNumber = undefined;
                      if (evt.target.value) {
                        phoneNumber = evt.target.value;
                      }

                      let phoneNumbers: string[] | undefined = undefined;

                      if (phoneNumber) {
                        phoneNumbers = phoneNumber
                          ?.split(",")
                          .map((phoneNumber) => "%" + phoneNumber.trim() + "%");
                      }
                      setFilterValues({
                        ...filterValues,
                        phoneNumber: phoneNumbers,
                      });
                    }}
                  />
                </Form.Group>
                <DateInput
                  label="Date of Birth Filter"
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  setApplyDateFilter={setApplyDateFilter}
                  setClearDateFilter={setClearDateFilter}
                />
                <Row>
                  <Col>
                    <Form.Group controlId="showDeleted">
                      <Form.Label>Show Deleted?</Form.Label>
                      <Form.Control
                        type="checkbox"
                        defaultChecked={filterValues.showDeleted}
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          setFilterValues({
                            ...filterValues,
                            showDeleted: evt.target.checked,
                          });
                        }}
                        style={{ width: "20px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="showActive">
                      <Form.Label>Show Only Active?</Form.Label>
                      <Form.Control
                        type="checkbox"
                        defaultChecked={filterValues.showActive}
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          setFilterValues({
                            ...filterValues,
                            showActive: evt.target.checked,
                          });
                        }}
                        style={{ width: "20px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {getPagination()}
      <Container className="px-4 mw-none w-100">
        <AccountTable accounts={accounts} />
      </Container>
      {getCreateAccountModal()}
    </>
  );
};

export default AccountList;
