import { BackOfficeAccount } from "src/models/admin/BackOfficeAccount";
import config from "src/config";

export function setAccount(token: string, account: BackOfficeAccount) {
  localStorage.setItem(
    config.SESSION_TOKEN_IDENTIFIER,
    JSON.stringify({ ...account, token }),
  );
}

export function getAccount(): BackOfficeAccount | null {
  const accountString: string | null = localStorage.getItem(
    config.SESSION_TOKEN_IDENTIFIER,
  );
  try {
    if (accountString) return JSON.parse(accountString) as BackOfficeAccount;
    return null;
  } catch (error) {
    deleteAccount();
    return null;
  }
}

export function deleteAccount() {
  localStorage.removeItem(config.SESSION_TOKEN_IDENTIFIER);
}
