import React, { useCallback } from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { AccountDocument } from "src/models/app/AccountDocument";
import { AccountDocumentStateCodes } from "src/models/app/AccountDocumentState";
import {
  RequestDocumentsListDto,
  ResponseGetAccountDocumentsDto,
} from "src/models/dto/document.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import DocumentTable from "./DocumentTable";

const DocumentList: FunctionComponent<any> = () => {
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationShowAmount] = useState<number>(7);

  const [documents, setDocuments] = useState<AccountDocument[]>([]);

  const updateDocuments = useCallback(async (): Promise<void> => {
    // Queries only the unverifed documents
    const documentsResponse: ResponseGetAccountDocumentsDto = await makeRequest<
      ResponseGetAccountDocumentsDto,
      RequestDocumentsListDto
    >("post", urls.DOCUMENTS_LIST, {
      where: {
        states: [AccountDocumentStateCodes.UNVERIFIED],
      },
      offset: paginationShowAmount * (paginationIndex - 1),
      limit: paginationShowAmount,
    });

    const unverifiedDocuments = documentsResponse.documents;

    // Filter the deleted documents
    setDocuments(unverifiedDocuments.filter((doc) => doc.deletedAt === null));

    setPaginationTotalPages(
      Math.ceil(documentsResponse.totalDocuments / paginationShowAmount),
    );
  }, [paginationIndex, paginationShowAmount]);

  const getPagination = (): React.ReactChild => {
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;

    const items = [];
    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    return (
      <Pagination className="m-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
            getPagination();
          }}
        />
      </Pagination>
    );
  };

  useEffect(() => {
    updateDocuments();
  }, [updateDocuments]);

  useEffect(() => {
    updateDocuments();
  }, [paginationShowAmount, paginationIndex, updateDocuments]);

  return (
    <>
      <Pagination className="m-4 flex-wrap">{getPagination()}</Pagination>
      <DocumentTable documents={documents}></DocumentTable>
    </>
  );
};

export default DocumentList;
