import React from "react";
import SpinnerComp from "../spinner/SpinnerComp";

interface IProps {
  isLoading: boolean;
}

const OverlayLoader = ({ isLoading }: IProps) => {
  if (!isLoading) return null;

  return (
    <div className="overlay">
      <SpinnerComp animation="border" />
    </div>
  );
};

export default OverlayLoader;
