/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import DateRange from "./DateRange";

interface IDateInputProps {
  setFromDate: any;
  setToDate: any;
  setApplyDateFilter: any;
  setClearDateFilter: any;
  label: string;
}

const DateInput: FunctionComponent<IDateInputProps> = (
  props: IDateInputProps,
) => {
  return (
    <Form.Group>
      <Form.Label className="text-base">{props.label}</Form.Label>
      <DateRange
        setFromDate={props.setFromDate}
        setToDate={props.setToDate}
        setApplyDateFilter={props.setApplyDateFilter}
        setClearDateFilter={props.setClearDateFilter}
      />
    </Form.Group>
  );
};

export default DateInput;
