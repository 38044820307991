import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { DepositLimit } from "src/models/app/DepositLimit";
import { makeRequest } from "src/utilities/axio.helper";
import { Account } from "src/models/app/Account";
import urls from "src/utilities/urls";
import { RequestUpsertDepositLimitDto } from "src/models/dto/depositLimit.dto";

export interface IAccountDepositLimitCreateModal {
  account: Account;
  removeModal: () => void;
  onSave: (depositLimits: DepositLimit[]) => void;
}

export const AccountDepositLimitCreateModal: FunctionComponent<
  IAccountDepositLimitCreateModal
> = (props: IAccountDepositLimitCreateModal) => {
  const [newDailyLimit, setNewDailyLimit] = useState<DepositLimit>(
    new DepositLimit(),
  );
  const [newWeeklyLimit, setNewWeeklyLimit] = useState<DepositLimit>(
    new DepositLimit(),
  );
  const [newMonthlyLimit, setNewMonthlyLimit] = useState<DepositLimit>(
    new DepositLimit(),
  );
  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setNewDailyLimit({ ...newDailyLimit, typeId: 1, account: props.account });
      setNewWeeklyLimit({
        ...newWeeklyLimit,
        typeId: 2,
        account: props.account,
      });
      setNewMonthlyLimit({
        ...newMonthlyLimit,
        typeId: 3,
        account: props.account,
      });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      newDailyLimit.amount <= 1000000 &&
      newDailyLimit.amount >= 100 &&
      newWeeklyLimit.amount <= 1000000 &&
      newWeeklyLimit.amount >= 100 &&
      newWeeklyLimit.amount >= newDailyLimit.amount &&
      newMonthlyLimit.amount <= 1000000 &&
      newMonthlyLimit.amount >= 100 &&
      newMonthlyLimit.amount >= newWeeklyLimit.amount
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [newDailyLimit, newWeeklyLimit, newMonthlyLimit]);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      const depLims = [newDailyLimit, newWeeklyLimit, newMonthlyLimit];

      await makeRequest<DepositLimit, RequestUpsertDepositLimitDto>(
        "post",
        urls.DEPOSIT_LIMITS_CREATE,
        {
          depositLimit: depLims,
        },
      );

      toastr.success("Deposit limits created!");

      props.onSave(depLims);
      onHide();
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="amount">
              <Form.Label>Daily Amount</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Amount.."
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setNewDailyLimit({
                    ...newDailyLimit,
                    amount: Number(e.target.value),
                    remaining: Number(e.target.value),
                  });
                }}
                isValid={
                  newDailyLimit.amount <= 1000000 && newDailyLimit.amount >= 100
                }
              />
              <Form.Label>Weekly Amount</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Amount.."
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setNewWeeklyLimit({
                    ...newWeeklyLimit,
                    amount: Number(e.target.value),
                    remaining: Number(e.target.value),
                  });
                }}
                isValid={
                  newWeeklyLimit.amount <= 1000000 &&
                  newWeeklyLimit.amount >= 100 &&
                  newWeeklyLimit.amount >= newDailyLimit.amount
                }
              />
              <Form.Label>Monthly Amount</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Amount.."
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setNewMonthlyLimit({
                    ...newMonthlyLimit,
                    amount: Number(e.target.value),
                    remaining: Number(e.target.value),
                  });
                }}
                isValid={
                  newMonthlyLimit.amount <= 1000000 &&
                  newMonthlyLimit.amount >= 100 &&
                  newMonthlyLimit.amount >= newWeeklyLimit.amount
                }
              />
            </Form.Group>
            <Modal.Footer>
              <Button
                disabled={!formValid}
                variant="primary"
                onClick={() => onSave()}
              >
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};
