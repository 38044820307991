import React, { FunctionComponent, useState, useEffect } from "react";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Card, Table } from "react-bootstrap";

import { Provider } from "src/models/app/Provider";
import { ProviderListItem } from "./ProviderListItem";

const ProvidersList: FunctionComponent<any> = () => {
  const [providers, setProviders] = useState<Provider[]>();

  useEffect(() => {
    async function loadProviders() {
      const response = await makeRequest<Provider[]>("post", urls.PROVIDERS);
      setProviders(response);
    }
    loadProviders();
  }, []);
  return (
    <>
      <Card>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {providers &&
                providers.map((provider: Provider) => {
                  return (
                    <ProviderListItem key={provider.id} provider={provider} />
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProvidersList;
