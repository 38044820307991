import React, { FunctionComponent } from "react";
import { Game } from "../../../models/app/Game";
import { Table } from "react-bootstrap";
import { Category } from "src/models/app/Category";

import TableRow from "./TableRow";

interface IGamesTable {
  games: Game[];
  categories: Category[];
  filterValues: any;
  paginationShowAmount: any;
  paginationIndex: any;
  setPaginationTotalPages: any;
  setGames: any;
}

const GamesTable: FunctionComponent<IGamesTable> = (props: IGamesTable) => {
  return (
    <Table hover className="bg-white">
      <thead>
        <tr className="text-center">
          <th>#</th>
          <th>Name</th>
          <th>External ID</th>
          <th>Game Categories</th>
          <th>Primary Category</th>
          <th>Rating</th>
          <th>Active</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {props.games.map((game: Game, index) => {
          return game ? (
            <TableRow
              key={game.id}
              game={game}
              index={index}
              categories={props.categories}
              paginationIndex={props.paginationIndex}
              games={props.games}
            />
          ) : (
            <></>
          );
        })}
      </tbody>
    </Table>
  );
};

export default GamesTable;
