/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Game } from "./Game";
import { Category } from "./Category";

export class GameCategory {
  gameId: number;

  categoryId: number;

  weight?: number;

  createdAt?: Date;

  deletedAt?: Date;

  game: Game;

  category: Category;
}
