import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import FileUpload from "../../../components/fileUpload/FileUpload";
import { ManualPaymentProps } from "./ManualPayment";

const ManualWithdraw: FunctionComponent<ManualPaymentProps> = ({
  onSubmit,
  account,
}) => {
  const [amount, setAmount] = useState("");
  const [modifyWagering, setModifyWagering] = useState(false);
  const [feeOption, setFeeOption] = useState(0);
  const [withdrawFee, setWithdrawFee] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    const calculateWithdrawFee = () => {
      const amountValue = parseFloat(amount);
      const feePercentage = feeOption / 100;
      const calculatedWithdrawFee = amountValue * feePercentage;
      setWithdrawFee(calculatedWithdrawFee.toFixed(2));
    };

    calculateWithdrawFee();
  }, [amount, feeOption]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onSubmit({
      amount,
      modifyWagering,
      withdrawFee,
      comments,
      type: "WITHDRAW",
    });
    setAmount("");
    setModifyWagering(false);
    setFeeOption(0);
    setWithdrawFee("");
    setComments("");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="debitAmount">
        <Form.Label>Debit Amount</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter debit amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="removeFromWagering">
        <Form.Check
          type="checkbox"
          label="Remove Amount from Wagering"
          checked={modifyWagering}
          onChange={(e: any) => setModifyWagering(e.target.checked)}
        />
      </Form.Group>
      <Form.Group controlId="feeOption">
        <Form.Check
          type="radio"
          label="0%"
          name="feeOption"
          checked={feeOption === 0}
          onChange={() => setFeeOption(0)}
        />
        <Form.Check
          type="radio"
          label="1.4%"
          name="feeOption"
          checked={feeOption === 1.4}
          onChange={() => setFeeOption(1.4)}
        />
        <Form.Check
          type="radio"
          label="2.8%"
          name="feeOption"
          checked={feeOption === 2.8}
          onChange={() => setFeeOption(2.8)}
        />
      </Form.Group>
      <Form.Group controlId="withdrawFee">
        <Form.Label>Withdraw Fee</Form.Label>
        <Form.Control
          type="number"
          placeholder="Withdraw fee"
          value={withdrawFee}
          onChange={(e) => setWithdrawFee(e.target.value)}
          readOnly
        />
      </Form.Group>
      <Form.Group controlId="commentsDebit">
        <Form.Label>Comments</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          required
        />
      </Form.Group>
      <Card.Header>Upload Document</Card.Header>
      <FileUpload account={account} documentType="manual-payments-docs" />
      <Button type="submit">Send</Button>
    </Form>
  );
};

export default ManualWithdraw;
