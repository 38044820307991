import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { PaymentProvidersList } from "./PaymentProviders/PaymentProvidersList";

const SettingsPage: FunctionComponent = () => {
  return (
    <div>
      <Switch>
        <Route path="/settings">
          <h3 className="text-center">Settings</h3>
          <PaymentProvidersList />
        </Route>
      </Switch>
    </div>
  );
};

export default SettingsPage;
