import React, { useEffect, useState } from "react";
import { RequestVerifyToken, ResponseVerifyToken } from "./models/dto/auth.dto";
import { makeRequest } from "./utilities/axio.helper";
import { deleteAccount, getAccount } from "./utilities/storage";
import urls from "./utilities/urls";
import config from "./config";
import AccountLogin from "./pages/Accounts/AccountLogin";

function withLogin(WrappedComponent: any) {
  function LoginComponent(props: any) {
    const [loggedIn, setLoggedIn] = useState<boolean>(
      localStorage.getItem(config.SESSION_TOKEN_IDENTIFIER) !== null,
    );
    const verifySession = async (): Promise<void> => {
      if (config.AUTH.LOGIN_DISABLED) {
        setLoggedIn(true);
        return;
      }

      let account = getAccount();
      let isStillLoggedIn = true;

      if (account) {
        if (account.token) {
          const response = await makeRequest<
            ResponseVerifyToken,
            RequestVerifyToken
          >("post", urls.AUTH_VERIFY_SESSION_TOKEN, {
            token: account.token,
          });

          if (response.isValid === false) {
            deleteAccount();
            account = null;
            isStillLoggedIn = false;
          }
        } else {
          isStillLoggedIn = true;
        }

        if (isStillLoggedIn) {
          setLoggedIn(true);
        }
      }

      if (account === null) {
        setLoggedIn(false);
      }
    };

    useEffect(() => {
      if (loggedIn === false) {
        toastr.success("You have been logged out.");
      }
    }, [loggedIn]);

    useEffect(() => {
      verifySession();
    }, []);

    return (
      <>
        {!loggedIn ? (
          <AccountLogin setLoggedIn={setLoggedIn}></AccountLogin>
        ) : (
          <WrappedComponent
            {...props}
            setLoggedIn={setLoggedIn}
            loggedIn={loggedIn}
          />
        )}
      </>
    );
  }
  return LoginComponent;
}

export default withLogin;
