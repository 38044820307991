/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  Pagination,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { Category } from "../../../models/app/Category";
import urls from "../../../utilities/urls";
import Multiselect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import {
  GetGamesWhereDto,
  RequestGetGamesDto,
  ResponseGetGamesDto,
} from "src/models/dto/game.dto";
import { Provider } from "src/models/app/Provider";
import {
  ResponseGetCategoryDto,
  RequestGetCategoryDto,
} from "src/models/dto/category.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { Game } from "src/models/app/Game";
import GamesTable from "./GamesTable";

interface IGameListProps {
  deleteGame: (gameId: number) => Promise<void>;
}

class FilterValues {
  id: number[] = [];
  name: string[] = [];
  categories: number[] = [];
  primaryCategories: string[] = [];
  providers: number[] = [];
  showDeleted = true;
  showActive = true;
}

export async function refreshGames(
  filterValues: any,
  paginationShowAmount: any,
  paginationIndex: any,
  setPaginationTotalPages: any,
  setGames: any,
  setTotalGames: any,
) {
  const where: GetGamesWhereDto = new GetGamesWhereDto();

  if (filterValues.id.length > 0) {
    where.id = filterValues.id;
  }

  if (filterValues.name.length > 0) {
    where.name = filterValues.name;
  }

  if (filterValues.providers.length > 0) {
    where.providers = filterValues.providers;
  }

  if (filterValues.categories.length > 0) {
    where.categories = filterValues.categories;
  }
  if (filterValues.primaryCategories.length > 0) {
    where.primaryCategories = filterValues.primaryCategories;
  }

  if (filterValues.showActive) {
    where.active = 1;
  } else {
    where.active = 0;
  }

  const getGamesResponse: ResponseGetGamesDto = await makeRequest<
    ResponseGetGamesDto,
    RequestGetGamesDto
  >("post", urls.GAMES_LIST, {
    where,
    limit: paginationShowAmount,
    offset: (paginationIndex - 1) * paginationShowAmount,
    withDeleted: filterValues.showDeleted,
  });

  setPaginationTotalPages(
    Math.ceil(getGamesResponse.totalGames / paginationShowAmount),
  );

  const gameRows = [] as any;

  for (let i = 0; i < getGamesResponse.games.length; i++) {
    gameRows.push(getGamesResponse.games[i]);
  }

  setGames(getGamesResponse.games);
  setTotalGames(getGamesResponse.totalGames);
}

const GameList: FunctionComponent<IGameListProps> = () => {
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationShowAmount] = useState<number>(8);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [totalGames, setTotalGames] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);

  const [filterValues, setFilterValues] = useState<FilterValues>(
    new FilterValues(),
  );

  const getCategoryById = (categoryId: number): Category =>
    categories.filter((category: Category) => category.id === categoryId)[0];

  const getProviderById = (id: number): Provider =>
    providers.filter((provider: Provider) => provider.id === id)[0];

  const fetchCategories = async (): Promise<void> => {
    const response = await makeRequest<
      ResponseGetCategoryDto,
      RequestGetCategoryDto
    >("post", `${urls.CATEGORIES_LIST}`);

    setCategories(response.categories);
  };

  const fetchProviders = async (): Promise<void> => {
    const providers = await makeRequest<Provider[], any>(
      "post",
      urls.PROVIDERS,
    );
    setProviders(providers);
  };

  useEffect(() => {
    fetchCategories();
    fetchProviders();
    refreshGames(
      filterValues,
      paginationShowAmount,
      paginationIndex,
      setPaginationTotalPages,
      setGames,
      setTotalGames,
    );
  }, []);

  useEffect(() => {
    setPaginationIndex(1);
    refreshGames(
      filterValues,
      paginationShowAmount,
      paginationIndex,
      setPaginationTotalPages,
      setGames,
      setTotalGames,
    );
  }, [filterValues]);

  useEffect(() => {
    refreshGames(
      filterValues,
      paginationShowAmount,
      paginationIndex,
      setPaginationTotalPages,
      setGames,
      setTotalGames,
    );
  }, [paginationIndex]);

  const getPagination = (): React.ReactChild => {
    const items = [];
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;

    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    return (
      <Pagination className="m-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
            getPagination();
          }}
        />
        <Alert variant={"success"} className="ml-2">
          Total Games: {totalGames}
        </Alert>
      </Pagination>
    );
  };

  const getProviderFilter = (): React.ReactElement => {
    if (!providers) {
      return <></>;
    }

    return (
      <Form.Group controlId="providerId">
        <Form.Label>Provider Id</Form.Label>
        <Multiselect
          value={filterValues.providers.map((providerId: number): Option => {
            const provider = getProviderById(providerId);
            return { value: provider.id, label: provider.name };
          })}
          options={providers.map((provider: Provider): Option => {
            return { label: provider.name, value: provider.id };
          })}
          onChange={(options: Option[]): void => {
            const selectedProviders = options.map(
              (option): number => option.value,
            );
            setFilterValues({
              ...filterValues,
              providers: selectedProviders,
            });
          }}
          labelledBy={"Primary Category"}
        />
      </Form.Group>
    );
  };

  const getCategoriesFilter = (): React.ReactElement => {
    if (!categories) {
      return <></>;
    }

    return (
      <Form.Group controlId="categories">
        <Form.Label>Categories</Form.Label>
        <Multiselect
          value={filterValues.categories.map((categoryId: number): Option => {
            const category = getCategoryById(categoryId);
            return { value: category.id, label: category.name };
          })}
          options={categories.map((category: Category): Option => {
            return { label: category.name, value: category.id };
          })}
          onChange={(options: Option[]): void => {
            const selectedCategories = options.map(
              (option): number => option.value,
            );
            setFilterValues({
              ...filterValues,
              categories: selectedCategories,
            });
          }}
          labelledBy={"categories"}
        />
      </Form.Group>
    );
  };
  const getPrimaryCategoriesFilter = (): React.ReactElement => {
    if (!categories) {
      return <></>;
    }

    return (
      <Form.Group controlId="primaryCategories">
        <Form.Label>Primary Category</Form.Label>
        <Multiselect
          value={filterValues.primaryCategories.map(
            (categoryCode: string): Option => {
              const category = categories.filter(
                (category) => category.code === categoryCode,
              )[0];

              return { value: category.code, label: category.name };
            },
          )}
          options={categories.map((category: Category): Option => {
            return { label: category.name, value: category.code };
          })}
          onChange={(options: Option[]): void => {
            const selectedCategories = options.map(
              (option): string => option.value,
            );
            setFilterValues({
              ...filterValues,
              primaryCategories: selectedCategories,
            });
          }}
          labelledBy={"Primary Category"}
        />
      </Form.Group>
    );
  };

  return (
    <>
      {/* FIXME  Removed Accordian due to dropdown not rendering over it */}
      {/* <Accordion defaultActiveKey="0"> */}
      <Card className="m-4">
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Filter
          </Accordion.Toggle>
        </Card.Header>
        {/* <Accordion.Collapse eventKey="0"> */}
        <Card.Body>
          <Form
            onSubmit={(e: React.FormEvent<HTMLElement>): void =>
              e.preventDefault()
            }
          >
            <Form.Group controlId="id">
              <Form.Label>Game Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Game Id"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  let id = undefined;
                  if (evt.target.value) {
                    id = evt.target.value;
                  }

                  let ids: number[] = [];
                  if (id) {
                    ids = id?.split(",").map((id) => Number(id));
                  }

                  setFilterValues({
                    ...filterValues,
                    id: ids,
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Label>Game Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Deadwood"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  let name = undefined;
                  if (evt.target.value) {
                    name = evt.target.value;
                  }

                  let names: string[] = [];

                  if (name) {
                    names = name
                      ?.split(",")
                      .map((name) => "%" + name.trim() + "%");
                  }

                  setFilterValues({
                    ...filterValues,
                    name: names,
                  });
                }}
              />
            </Form.Group>

            {getProviderFilter()}
            {getCategoriesFilter()}
            {getPrimaryCategoriesFilter()}

            <Row>
              <Col>
                <Form.Group controlId="showDeleted">
                  <Form.Label>Show Deleted?</Form.Label>
                  <Form.Control
                    type="checkbox"
                    defaultChecked={filterValues.showDeleted}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFilterValues({
                        ...filterValues,
                        showDeleted: evt.target.checked,
                      });
                    }}
                    style={{ width: "20px" }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="showActive">
                  <Form.Label>Show Only Active?</Form.Label>
                  <Form.Control
                    type="checkbox"
                    defaultChecked={filterValues.showActive}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFilterValues({
                        ...filterValues,
                        showActive: evt.target.checked,
                      });
                    }}
                    style={{ width: "20px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        {/* </Accordion.Collapse> */}
      </Card>
      {/* </Accordion> */}

      {getPagination()}

      <Container className="px-4 mw-none w-100">
        <GamesTable
          categories={categories}
          games={games}
          filterValues={filterValues}
          paginationShowAmount={paginationShowAmount}
          paginationIndex={paginationIndex}
          setPaginationTotalPages={setPaginationTotalPages}
          setGames={setGames}
        />
      </Container>
    </>
  );
};

export default GameList;
