import React, { FunctionComponent } from "react";
import { Card, Container } from "react-bootstrap";
import Logo from "src/components/logo/logo";
interface WrapperProps {
  loading?: boolean;
}

const DefaultContainerWrapper: FunctionComponent<WrapperProps> = ({
  children,
  loading,
}) => {
  return (
    <div className="d-flex justify-content-center pt-11">
      <Card
        style={{
          width: "36rem",
        }}
      >
        <Card.Body>
          <Container className="text-center pt-2">
            {!loading && (
              <>
                <h2>FrejaCasino</h2>
                <h5>Back Office</h5>
                <Logo size="20rem" />
              </>
            )}
          </Container>

          <Container className="text-center pt-2">{children}</Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DefaultContainerWrapper;
