/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { GameCategory } from "./GameCategory";
import { Game } from "./Game";

export class Category {
  id: number;

  name: string;

  code: string;

  active: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  games: Game[];

  isEvent: number;

  gameCategories: GameCategory[];

  primaryGames: Game[];
}
