import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { setAccount } from "src/utilities/storage";
import { makeRequest } from "src/utilities/axio.helper";
import { ResponseLogin, RequestLogin } from "src/models/dto/auth.dto";
import urls from "src/utilities/urls";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import DefaultContainerWrapper from "./DefaultContainerWrapper";

const AccountLogin: FunctionComponent<{
  setLoggedIn: (value: boolean) => void;
}> = ({ setLoggedIn }) => {
  const signIn = async (response: CredentialResponse): Promise<void> => {
    try {
      const backEndResponse = await makeRequest<ResponseLogin, RequestLogin>(
        "post",
        urls.AUTH_LOGIN,
        {
          accessToken: response.credential,
        },
      );

      setAccount(backEndResponse.token, backEndResponse.account);
      setLoggedIn(true);
      toastr.success("Welcome, " + backEndResponse.account.name);
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  return (
    <DefaultContainerWrapper>
      <Container className="d-flex justify-content-md-center text-center p-4  ">
        <GoogleLogin
          onSuccess={(response: CredentialResponse) => {
            signIn(response);
          }}
          onError={() => {
            toastr.error("Unable to log In");
          }}
          size={"large"}
          theme={"filled_blue"}
        />
      </Container>
    </DefaultContainerWrapper>
  );
};

export default AccountLogin;
