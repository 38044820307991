import React, { FunctionComponent, useEffect, useState } from "react";
import { Game } from "../../../models/app/Game";
import Multiselect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import { Category } from "../../../models/app/Category";
import SpinnerComp from "src/components/spinner/SpinnerComp";

interface IGameCategorySelectorProps {
  game: Game;
  onChangeCategories: (categories: Category[]) => void;
  singleSelect?: boolean;
  chosenCategories?: Category[];
  categories: Category[];
  defaultValue?: number;
}

const GameCategorySelector: FunctionComponent<IGameCategorySelectorProps> = (
  props: IGameCategorySelectorProps,
) => {
  const [chosenCategories, setChosenCategories] = useState<Option[]>([]);

  useEffect(() => {
    if (props.chosenCategories) {
      setChosenCategories(
        props.chosenCategories.map((category: Category): Option => {
          return {
            value: category.id,
            label: category.name,
          };
        }),
      );
    }
  }, [props.chosenCategories]);

  const getCategoryById = (categoryId: number): Category =>
    props.categories?.filter(
      (category: Category) => category.id === categoryId,
    )[0];

  if (!props.categories) {
    return <SpinnerComp animation="grow" />;
  }

  return (
    <Multiselect
      options={props.categories.map((category: Category): Option => {
        return { label: category.name, value: category.id };
      })}
      value={chosenCategories}
      hasSelectAll={props.singleSelect === false}
      onChange={async (options: Option[]) => {
        if (props.singleSelect && options.length > 0) {
          const currentSelection = options[options.length - 1];
          options = [];
          options[0] = currentSelection;
        }

        if (options.length === 0 && props.defaultValue !== undefined) {
          options.push({
            label: props.categories[props.defaultValue].name,
            value: props.categories[props.defaultValue].id,
          });
        }
        setChosenCategories(options);

        if (props.onChangeCategories) {
          props.onChangeCategories(
            options.map(
              (option: Option): Category => getCategoryById(option.value),
            ),
          );
        }
      }}
      labelledBy={"Game Categories"}
    />
  );
};

GameCategorySelector.defaultProps = {
  singleSelect: false,
  chosenCategories: [],
  defaultValue: undefined,
};

export default GameCategorySelector;
