import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Button } from "react-bootstrap";

interface PDFExportOptions {
  title: string;
  head: string[];
  body: any;
  outputFile: string;
}

const PDFExport = (exportOpts: PDFExportOptions) => {
  function exportToPDF() {
    const doc = new jsPDF();

    doc.setFontSize(12);
    doc.text(exportOpts.title, 30, 35);

    autoTable(doc, {
      startY: 50,
      head: [exportOpts.head],
      body: exportOpts.body,
    });

    doc.save(exportOpts.outputFile + ".pdf");
  }

  return (
    <Button className="ml-2" onClick={exportToPDF} id="export-pdf-btn">
      Export PDF
    </Button>
  );
};

export default PDFExport;
