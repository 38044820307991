import React, { Component } from "react";
import { makeRequest } from "../../utilities/axio.helper";
import { Account } from "../../models/app/Account";
import { Route, Switch } from "react-router-dom";
import urls from "../../utilities/urls";
import { RequestDeleteAccountDto } from "src/models/dto/account.dto";
import { CategoriesList } from "./CategoriesList/CategoriesList";
import CategoryGamesList from "./CategoryGamesList/CategoryGamesList";

export class CategoriesPage extends Component {
  async deleteCategory(accountId: number): Promise<void> {
    try {
      await makeRequest<Account, RequestDeleteAccountDto>(
        "delete",
        urls.ACCOUNTS_LIST,
        {
          id: accountId,
        },
      );
    } catch (err) {
      toastr.error((err as any).message);
    }
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/categories/gamelist/:categoryId">
            <CategoryGamesList />
          </Route>
          <Route path="/categories">
            <CategoriesList />
          </Route>
        </Switch>
      </div>
    );
  }
}
