import React, { FunctionComponent } from "react";
import { makeRequest } from "../../utilities/axio.helper";
import { Route, Switch } from "react-router-dom";
import AccountList from "./AccountList/AccountList";
import { Account } from "../../models/app/Account";
import AccountEditPage from "./AccountEditPage";
import urls from "../../utilities/urls";
import { RequestDeleteAccountDto } from "src/models/dto/account.dto";
import { Jumbotron } from "react-bootstrap";

const AccountsPage: FunctionComponent = () => {
  const deleteAccount = async (accountId: number): Promise<void> => {
    try {
      await makeRequest<Account, RequestDeleteAccountDto>(
        "delete",
        urls.ACCOUNTS_LIST,
        {
          id: accountId,
        },
      );
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  return (
    <div>
      <Switch>
        <Route path="/accounts/edit/:accountId?/:focusedTab?">
          <AccountEditPage />
        </Route>
        <Route path="/accounts">
          <Jumbotron className="m-4">
            <h3>Accounts List</h3>

            <AccountList deleteAccount={deleteAccount}></AccountList>
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default AccountsPage;
