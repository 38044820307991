import React from "react";
import { Button } from "react-bootstrap";

interface CSVExportOptions {
  title: string;
  head: string[];
  body: any;
  outputFile: string;
}

const CSVExport = (exportOpts: CSVExportOptions) => {
  function exportToCSV() {
    const csvString = [exportOpts.head, ...exportOpts.body]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([exportOpts.title + "\n" + csvString], {
      type: "text/csv;charset=utf-8,",
    });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", "Transactions.csv");
    link.click();
  }

  return (
    <Button className="ml-2" onClick={exportToCSV} id="export-pdf-btn">
      Export CSV
    </Button>
  );
};

export default CSVExport;
